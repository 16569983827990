import Navbar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import "../Condiciones/Conditions.css";

const Privacy = () =>{
    return(
        <div className="mainCondition">
      <Navbar />
      <div className="subMainCondition">
      <h1>Pol&iacute;tica de Privacidad</h1>
<p>HearBound&nbsp;te informa sobre su Pol&iacute;tica de Privacidad respecto del tratamiento y protecci&oacute;n de los datos de car&aacute;cter personal de los usuarios y clientes que puedan ser recabados por la navegaci&oacute;n, uso o contrataci&oacute;n de los diferentes servicios a trav&eacute;s del sitio Web https://www.hearbound.com/ y el uso de las diferentes plataformas&nbsp;de HearBound.</p>
<p>En este sentido, HearBound garantiza el cumplimiento de la normativa vigente en materia de protecci&oacute;n de datos personales, reflejada en la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y de Garant&iacute;a de Derechos Digitales (LOPD GDD). Cumple tambi&eacute;n con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (RGPD).</p>
<p>En esta&nbsp;Pol&iacute;tica de Privacidad&nbsp;encontrar&aacute;s&nbsp;toda la informaci&oacute;n relevante&nbsp;que aplica al&nbsp;uso&nbsp;que hacemos de los&nbsp;datos personales&nbsp;de nuestros&nbsp;clientes y usuarios, con independencia del canal o medio (como&nbsp;online, por tel&eacute;fono, o por correo electr&oacute;nico) que utilices para interactuar con nosotros.</p>
<p>Su privacidad es muy importante para nosotros, por lo que nos esforzamos por cumplir de manera diligente toda la normativa sobre Protecci&oacute;n de Datos de Car&aacute;cter Personal. Esta Pol&iacute;tica de Privacidad le servir&aacute; para conocer c&oacute;mo y con qu&eacute; fines tratamos o cedemos su informaci&oacute;n, as&iacute; como por cuanto tiempo; y los derechos que le asisten con respecto a la misma.</p>
<p>El uso de sitio Web implica la aceptaci&oacute;n de esta Pol&iacute;tica de Privacidad, as&iacute; como las condiciones incluidas en el Aviso Legal.</p>
<h1>Identidad del responsable</h1>
<ul>
<li>Titular:&nbsp;HearBound SL</li>
<li>NIF: en proceso</li>
<li>Domicilio:&nbsp;C. de Villablanca, 85, 28032 Madrid</li>
<li>Correo electr&oacute;nico:&nbsp;contacto@hearbound.com</li>
<li>Sitio Web:&nbsp; https://www.hearbound.com/</li>
</ul>
<h1>Principios aplicados en el tratamiento de datos</h1>
<p>En el tratamiento de tus datos personales, HearBound aplicar&aacute; los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protecci&oacute;n de datos:</p>
<ul>
<li>Principio de licitud, lealtad y transparencia:&nbsp;HearBound siempre requerir&aacute; el consentimiento para el tratamiento de tus datos personales que puede ser para uno o varios fines espec&iacute;ficos sobre los que te informar&aacute; previamente con absoluta transparencia.</li>
<li>Principio de minimizaci&oacute;n de datos:&nbsp;HearBound solicitar&aacute; solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
<li>Principio de limitaci&oacute;n del plazo de conservaci&oacute;n:&nbsp;Los datos se mantendr&aacute;n durante el tiempo estrictamente necesario para el fin o los fines del tratamiento. En otros casos, HearBound te informar&aacute; del plazo de conservaci&oacute;n correspondiente seg&uacute;n la finalidad. Principio de integridad y confidencialidad:&nbsp;Tus datos ser&aacute;n tratados de tal manera que su seguridad, confidencialidad e integridad est&eacute; garantizada. Debes saber que el Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
</ul>
<h1>Tus derechos</h1>
<p>Las leyes de privacidad, incluido el Reglamento general de protecci&oacute;n de datos ("RGPD"), otorgan derechos a las personas sobre sus datos personales. aLos derechos que podr&aacute; ejercitar con respecto al tratamiento de sus datos personales son:</p>
<ul>
<li>Acceso: Estar informado/a de los datos personales que tratamos sobre ti y solicitar el acceso a los mismos.</li>
<li>Rectificaci&oacute;n: Solicitar que modifiquemos o actualicemos tus datos personales cuando sean inexactos o est&eacute;n incompletos.</li>
<li>Cancelaci&oacute;n: Solicitar que eliminemos algunos de tus datos personales.</li>
<li>Limitaci&oacute;n: Solicitar que se detenga de manera temporal o permanente el tratamiento de todos o algunos de tus datos personales</li>
<li>Oposici&oacute;n: Oponerse al tratamiento de tus datos personales en cualquier momento, por motivos relacionados con tu situaci&oacute;n particular. Oponerse al tratamiento de tus datos personales con fines de marketing directo.</li>
<li>Portabilidad de datos: Solicitar una copia de tus datos personales en un formato electr&oacute;nico y el derecho a transmitir dichos datos personales para su uso en el servicio de un tercero.</li>
<li>No ser objeto de una toma de decisiones automatizada: No ser objeto de decisiones basadas exclusivamente en una toma de decisiones automatizada (decisiones sin participaci&oacute;n humana), incluida la creaci&oacute;n de perfiles, en las que la decisi&oacute;n podr&iacute;a afectarte legalmente o con similares consecuencias.</li>
</ul>
<p><strong>C&oacute;mo ejercer tus derechos</strong></p>
<p><strong>Acceso: </strong>para solicitar una copia de tus datos personales, env&iacute;a un correo a contacto@hearbound.com</p>
<p><strong>Rectificaci&oacute;n</strong>: puedes editar tus datos de usuario editando tu perfil de HearBound.</p>
<p><strong>Cancelaci&oacute;n:</strong></p>
<ul>
<li>Para eliminar el contenido de audio guardado en tu perfil puedes seleccionar el contenido correspondiente y eligiendo la opci&oacute;n correspondiente.</li>
<li>Para cancelar otros datos personales de HearBound, consulta la p&aacute;gina de asistencia (cerrar cuenta)</li>
</ul>
<p><strong>Otros derechos</strong>: puedes ponerte en contacto directamente con HearBound&nbsp; para ejercer tus derechos en cualquier momento a trav&eacute;s del siguiente correo electr&oacute;nico: contacto@hearbound.com.</p>
<p>Adem&aacute;s, en todas las comunicaciones comerciales se indicar&aacute; siempre la direcci&oacute;n de correo electr&oacute;nico en la que pueda ejercer su derecho de oposici&oacute;n al tratamiento de sus datos con fines promocionales. Cualquier solicitud para el ejercicio de derechos por correo electr&oacute;nico, deber&aacute; acompa&ntilde;arse con copia del documento que acredite su identidad.</p>
<p>El ejercicio de tus derechos no incluye ning&uacute;n dato que HearBound est&eacute; obligado a conservar con fines administrativos, legales o de seguridad.</p>
<p>Finalmente, de acuerdo con los t&eacute;rminos establecidos en la legislaci&oacute;n vigente, puede Ud. revocar en cualquier momento los consentimientos que nos haya facilitado. Adem&aacute;s, si considera que sus datos no han sido tratados conforme a la normativa vigente, puede presentar una denuncia ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos.</p>
<p><strong>Datos que recogemos y procedencia </strong></p>
<table border="1" cellspacing="1" cellpadding="1">
<tbody>
<tr>
<td colspan="5" valign="bottom" width="566">
<p>Recopilados cuando te registras en HearBound o al actualizar tu cuenta</p>
</td>
</tr>
<tr>
<td colspan="2" valign="top" width="134">
<p>Datos de usuario</p>
</td>
<td colspan="3" valign="top" width="432">
<p>Datos personales que necesitamos para crear tu cuenta de HearBound y que te permiten utilizar la Web y las plataformas.</p>
<p>El tipo de datos recopilados depende del tipo de Servicio que tengas, de c&oacute;mo crees tu cuenta, del pa&iacute;s en el que te encuentres y de si utilizas servicios de terceros para iniciar sesi&oacute;n. Estos pueden incluir tu:</p>
<ul>
<li>nombre del perfil</li>
<li>correo electr&oacute;nico</li>
<li>n&uacute;mero de tel&eacute;fono</li>
<li>fecha de nacimiento</li>
<li>g&eacute;nero</li>
<li>direcci&oacute;n</li>
<li>pa&iacute;s</li>
</ul>
<p>Recogemos parte de estos datos, por ejemplo, del formulario de registro o de la p&aacute;gina de la cuenta.</p>
<p>Tambi&eacute;n recopilamos algunos de estos datos de tu dispositivo, por ejemplo, del pa&iacute;s.</p>
</td>
</tr>
<tr>
<td colspan="2" valign="top" width="134">
<p>Datos de direcci&oacute;n</p>
</td>
<td colspan="3" valign="top" width="432">
<p>Es posible que le pidamos su direcci&oacute;n postal por las siguientes razones:</p>
<ul>
<li>Para comprobar la elegibilidad para una opci&oacute;n de servicio</li>
<li>para la administraci&oacute;n fiscal</li>
</ul>
<p>En algunos casos, podemos utilizar una aplicaci&oacute;n de mapas de terceros (como Google Maps) para ayudarle a verificar su direcci&oacute;n.</p>
</td>
</tr>
<tr>
<td colspan="5" valign="bottom" width="566">
<p>Recopilados a trav&eacute;s de tu uso de HearBound</p>
</td>
</tr>
<tr>
<td valign="top" width="105">
<p>Datos de uso</p>
</td>
<td colspan="4" valign="top" width="461">
<p>Datos personales recopilados sobre ti cuando accedes a la Web o utilizas HearBound. Existen varios tipos de informaci&oacute;n que se detallan en las siguientes secciones.</p>
<p>&nbsp;</p>
<p><strong>Informaci&oacute;n sobre c&oacute;mo utilizas HearBound</strong></p>
<p>&nbsp;</p>
<p>Los ejemplos incluyen:</p>
<p>&nbsp;</p>
<ul>
<li>Tus acciones con el Servicio HearBound (incluyendo fecha y hora), como por ejemplo:
<ul>
<li>consultas de b&uacute;squeda</li>
<li>historial de canciones</li>
<li>tu biblioteca</li>
<li>historial de navegaci&oacute;n</li>
<li>configuraci&oacute;n de la cuenta</li>
<li>interacciones con otros usuarios de HearBiybd</li>
<li>tu uso de servicios, dispositivos y aplicaciones de terceros en relaci&oacute;n con HearBound</li>
<li>Inferencias (es decir, nuestra comprensi&oacute;n) de tus intereses y preferencias en base a tu uso de HearBound.</li>
<li>Contenido que publiques en cualquier elemento del Servicio de HearBound, como im&aacute;genes, v&iacute;deo, texto, comunicaciones y otros tipos de contenido.</li>
<li>&nbsp;</li>
</ul>
</li>
</ul>
<p><strong>Tus datos t&eacute;cnicos</strong></p>
<p>&nbsp;</p>
<p>Los ejemplos incluyen:</p>
<ul>
<li>informaci&oacute;n sobre la URL</li>
<li>identificadores online, como los datos de cookies y direcciones IP</li>
<li>Informaci&oacute;n sobre los dispositivos que utilizas, como:
<ul>
<li>ID de dispositivo</li>
<li>tipo de conexi&oacute;n de red (por ejemplo, Wi-Fi, 4G, LTE, Bluetooth)</li>
<li>proveedor</li>
<li>rendimiento de la red y del dispositivo</li>
<li>tipo de navegador</li>
<li>idioma</li>
<li>informaci&oacute;n que permite la gesti&oacute;n de derechos digitales</li>
<li>sistema operativo</li>
<li>versi&oacute;n de la aplicaci&oacute;n HearBound</li>
<li>Informaci&oacute;n (como el nombre del dispositivo, los identificadores del dispositivo, la marca y la versi&oacute;n) que nos permite descubrir y conectar con dispositivos y aplicaciones de terceros, como los que se enumeran a continuaci&oacute;n:
<ul>
<li>dispositivos de tu red Wi-Fi que se pueden conectar al servicio de HearBound</li>
<li>dispositivos puestos a disposici&oacute;n por tu sistema operativo cuando se conectan a trav&eacute;s de Bluetooth, plugin e instalaci&oacute;n</li>
<li>aplicaciones de socio de HearBound para determinar si la aplicaci&oacute;n est&aacute; instalada en tu dispositivo</li>
</ul>
</li>
</ul>
</li>
</ul>
<p>Tu ubicaci&oacute;n general (no exacta). Se puede deducir a partir de datos t&eacute;cnicos (por ejemplo, tu direcci&oacute;n IP, la configuraci&oacute;n del idioma de tu dispositivo o la moneda de pago). La necesitamos para cumplir los requisitos geogr&aacute;ficos de nuestros acuerdos con los propietarios de los contenidos de HearBound y para ofrecer contenidos y publicidad relevantes para ti.</p>
<p>&nbsp;</p>
<p><strong>Datos de los sensores de tu dispositivo</strong></p>
<p>&nbsp;</p>
<p>Datos de los sensores m&oacute;viles generados por el movimiento o la orientaci&oacute;n (por ejemplo, el aceler&oacute;metro o el giroscopio) si son necesarios para proporcionar funciones de HearBound y que requieren dichos datos.</p>
</td>
</tr>
<tr>
<td colspan="5" valign="bottom" width="566">
<p>Datos adicionales que tu decidas facilitarnos</p>
</td>
</tr>
<tr>
<td colspan="3" valign="top" width="146">
<p>Datos de pago y compra</p>
</td>
<td colspan="2" valign="top" width="420">
<p>Si utilizas las funciones de promoci&oacute;n de pago, necesitaremos tratar tus datos de pago.</p>
<p>Los datos personales exactos recopilados variar&aacute;n en funci&oacute;n del m&eacute;todo de pago, pero incluir&aacute;n informaci&oacute;n como:</p>
<ul>
<li>nombre</li>
<li>fecha de nacimiento</li>
<li>el tipo de tarjeta de cr&eacute;dito o d&eacute;bito, la fecha de vencimiento y determinados d&iacute;gitos de tu n&uacute;mero de tarjeta<br /> Nota: Por seguridad, nunca almacenamos el n&uacute;mero completo de tu tarjeta</li>
<li>c&oacute;digo postal</li>
<li>n&uacute;mero de tel&eacute;fono m&oacute;vil</li>
<li>detalles de tu historial de compras y pagos</li>
</ul>
</td>
</tr>
<tr>
<td colspan="3" valign="top" width="146">
<p>datos de encuestas, concursos y sorteos</p>
</td>
<td colspan="2" valign="top" width="420">
<p>Cuando rellenas cualquier formulario, respondes a una encuesta o cuestionario, o participas en un concurso o sorteo, recopilamos los datos personales que proporcionas.</p>
</td>
</tr>
<tr>
<td colspan="5" valign="bottom" width="566">
<p>Recopilados de otras fuentes (&ldquo;terceros&rdquo;)</p>
</td>
</tr>
<tr>
<td colspan="4" valign="top" width="171">
<p>Socios de autenticaci&oacute;n</p>
</td>
<td valign="top" width="395">
<p>Si te registras o inicias sesi&oacute;n en nuestros servicios utilizando otro servicio, recibiremos tu informaci&oacute;n por parte de este &uacute;ltimo para ayudar a crear tu cuenta con nosotros.</p>
</td>
</tr>
<tr>
<td colspan="4" valign="top" width="171">
<p>Aplicaciones, servicios y dispositivos de terceros que conectes a tu cuenta de HearBound.</p>
</td>
<td valign="top" width="395">
<p>Si conectas tu cuenta de HearBound a una aplicaci&oacute;n, servicio y/o dispositivos de terceros, podremos recabar cierta informaci&oacute;n de los mismos para hacer posible la integraci&oacute;n.</p>
<p>Estas aplicaciones, servicios o dispositivos de terceros pueden incluir:</p>
<ul>
<li>redes sociales</li>
<li>dispositivos que incluyen:
<ul>
<li>audio (por ejemplo, auriculares)</li>
<li>tel&eacute;fonos m&oacute;viles y tabletas</li>
<li>servicios o plataformas como asistentes de voz</li>
</ul>
</li>
</ul>
<p>Te pediremos permiso antes de recopilar tu informaci&oacute;n de determinados terceros.</p>
</td>
</tr>
<tr>
<td colspan="4" valign="top" width="171">
<p>Socios de servicio t&eacute;cnico</p>
</td>
<td valign="top" width="395">
<p>Trabajamos con socios de servicio t&eacute;cnico que nos proporcionan determinados datos, como la asignaci&oacute;n de direcciones IP a datos de localizaci&oacute;n no exactos (por ejemplo, ciudad, estado).</p>
<p>De este modo, HearBound puede proporcionar el Servicio, los contenidos y las funciones.</p>
</td>
</tr>
<tr>
<td colspan="4" valign="top" width="171">
<p>Socios de pago</p>
</td>
<td valign="top" width="395">
<p>Si decides pagar a HearBound mediante factura, podremos obtener datos de nuestros socios de pago.</p>
<p>Esto nos permite:</p>
<ul>
<li>enviarte facturas</li>
<li>procesar tus pagos</li>
<li>darte lo que has comprado</li>
</ul>
</td>
</tr>
<tr>
<td colspan="4" valign="top" width="171">
<p>Socios de publicidad y marketing</p>
</td>
<td valign="top" width="395">
<p>Podemos obtener datos sobre ti de determinados socios de publicidad o marketing.</p>
<p>Dichos datos pueden incluir:</p>
<ul>
<li>ID de cookies</li>
<li>ID de dispositivos m&oacute;viles</li>
<li>correo electr&oacute;nico</li>
<li>inferencias (es decir, nuestra comprensi&oacute;n) de sus intereses y preferencias</li>
</ul>
<p>Esto nos permite ofrecer anuncios y marketing m&aacute;s pertinentes.</p>
</td>
</tr>
<tr>

</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<h1>Legitimaci&oacute;n y finalidad</h1>
<p>La informaci&oacute;n que facilitas puede incluir datos de car&aacute;cter personal como pueden ser tu direcci&oacute;n IP, nombre y apellidos, direcci&oacute;n f&iacute;sica, direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono, y otra informaci&oacute;n. Al facilitar esta informaci&oacute;n, das tu consentimiento para que tu informaci&oacute;n sea recopilada, utilizada, gestionada y almacenada por HearBound.com, s&oacute;lo como se describe los T&eacute;rminos y Condiciones y en la presente Pol&iacute;tica de Privacidad.</p>
<p>Los datos personales y la finalidad del tratamiento por parte de HearBound es diferente seg&uacute;n el sistema de captura de informaci&oacute;n, cuya finalidad es diferente seg&uacute;n los diferentes casos. La legitimaci&oacute;n de los datos que recopilamos provienen de:</p>
<ul>
<li><strong>Ejecuci&oacute;n de un contrato</strong>: cuando es necesario que HearBound (o un tercero) trate tus datos personales para:
<ul>
<li>cumplir las obligaciones derivadas de un contrato celebrado contigo, por ejemplo, las obligaciones de HearBound en virtud de los T&eacute;rminos de uso para prestarte el Servicio de HearBound</li>
<li>o verificar la informaci&oacute;n antes de que comience un nuevo contrato contigo.</li>
<li><strong>Inter&eacute;s leg&iacute;timo</strong>: cuando HearBound (o un tercero) tenga inter&eacute;s en utilizar tus datos personales de una forma determinada, que sea necesaria y est&eacute; justificada teniendo en cuenta los posibles riesgos para ti y otros usuarios de HearBound. Por ejemplo, utilizar tus Datos de uso para mejorar el Servicio de HearBound para todos los usuarios.</li>
<li><strong>Consentimiento:</strong> cuando HearBound te pida que indiques activamente que est&aacute;s de acuerdo con el uso de tus datos personales por parte de HearBound para una o varias finalidades determinadas.</li>
<li><strong>Cumplimiento de obligaciones legales</strong>: cuando HearBound debe tratar tus datos personales para cumplir con una ley</li>
</ul>
</li>
</ul>
<table border="1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td valign="bottom" width="224">
<p>Finalidad del tratamiento de tus datos</p>
</td>
<td valign="bottom" width="160">
<p>Base jur&iacute;dica que permite la finalidad</p>
</td>
<td valign="bottom" width="182">
<p>Categor&iacute;as de datos personales utilizadas para la finalidad</p>
</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para prestar el Servicio de HearBound personalizado.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Ejecuci&oacute;n de un contrato</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Consentimiento</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
<li>Datos de direcci&oacute;n</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para comprender, diagnosticar, detectar y solucionar problemas con el Servicio de HearBound</p>
</td>
<td valign="top" width="160">
<ul>
<li>Ejecuci&oacute;n de un contrato</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para evaluar y desarrollar nuevas caracter&iacute;sticas, tecnolog&iacute;as y mejoras del Servicio de HearBound</p>
</td>
<td valign="top" width="160">
<ul>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Consentimiento</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
</ul>
</td>
<td valign="top" width="182">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para fines de marketing, promoci&oacute;n y publicidad.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Consentimiento</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de encuestas, concursos y sorteos</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para cumplir las obligaciones legales y las solicitudes de cumplimiento de la ley.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Cumplimiento de las obligaciones legales</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
<li>Datos de direcci&oacute;n</li>
<li>Datos de encuestas, concursos y sorteos</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para:</p>
<ul>
<li>cumplir las obligaciones contractuales con terceros, por ejemplo, nuestros acuerdos con los propietarios de contenidos en el Servicio de HearBound, y</li>
<li>adoptar las medidas oportunas ante las denuncias de infracci&oacute;n de la propiedad intelectual y de contenidos inapropiados.</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
</ul>
</td>
<td valign="top" width="160">&nbsp;</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para establecer, ejercer o defender reclamaciones.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
<li>Datos de direcci&oacute;n</li>
<li>Datos de encuestas, concursos y sorteos</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para fines de planificaci&oacute;n, elaboraci&oacute;n de informes y previsiones.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para procesar pagos.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Ejecuci&oacute;n de un contrato</li>
<li>Cumplimiento de las obligaciones legales</li>
<li>Datos de usuario</li>
<li>Datos de pago y compra</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para detectar y prevenir fraudes, incluidos pagos fraudulentos y el uso fraudulento de HearBound</p>
</td>
<td valign="top" width="160">
<ul>
<li>Ejecuci&oacute;n de un contrato</li>
<li>Cumplimiento de las obligaciones legales</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de pago y compra</li>
<li>Datos de direcci&oacute;n</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
<tr>
<td valign="top" width="224">
<p>Para realizar estudios, concursos, encuestas y sorteos.</p>
</td>
<td valign="top" width="160">
<ul>
<li>Ejecuci&oacute;n de un contrato</li>
<li>Inter&eacute;s leg&iacute;timo</li>
<li>Consentimiento</li>
<li>Datos de usuario</li>
<li>Datos de uso</li>
<li>Datos de encuestas, concursos y sorteos</li>
</ul>
</td>
<td valign="top" width="182">&nbsp;</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>Existen otras finalidades por las que HearBound trata tus datos personales:</p>
<ul>
<li>Para gestionar las redes sociales. HearBound tiene presencia en redes sociales. Si te haces seguidor en las redes sociales de HearBound, el tratamiento de los datos personales se regir&aacute; por este apartado, as&iacute; como por aquellas condiciones de uso, pol&iacute;ticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
</ul>
<p>HearBound tratar&aacute; tus datos personales con la finalidad de administrar correctamente su presencia en la red social, informarte de sus actividades, productos o servicios, as&iacute; como para cualquier otra finalidad que las normativas de las redes sociales permitan.</p>
<p>En ning&uacute;n caso HearBound utilizar&aacute; los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.</p>
<h1>Terceros</h1>
<p>I<strong>nformaci&oacute;n p&uacute;blicamente disponible</strong></p>
<p>Los siguientes datos personales estar&aacute;n siempre disponibles p&uacute;blicamente HearBound:</p>
<ul>
<li>Tu nombre de perfil</li>
<li>A qui&eacute;n sigues en&nbsp; HearBound</li>
<li>Qui&eacute;n te sigue en HearBound (puedes eliminar seguidores)</li>
</ul>
<p><strong>Datos personales que puedes optar por compartir</strong></p>
<p>&Uacute;nicamente compartiremos los siguientes datos personales con las personas indicadas en la tabla siguiente:</p>
<ul>
<li>cuando sea necesario compartir determinados datos personales para el uso de una funci&oacute;n de HearBound o</li>
<li>si nos autorizas a compartir los datos personales, por ejemplo, seleccionando la configuraci&oacute;n adecuada en HearBound o dando tu consentimiento</li>
</ul>
<table border="1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td valign="bottom" width="283">
<p>Categor&iacute;as de destinatarios</p>
</td>
<td valign="bottom" width="283">
<p>Motivo para compartir</p>
</td>
</tr>
<tr>
<td valign="top" width="283">
<p>Otros usuarios de HearBound</p>
</td>
<td valign="top" width="283">
<p>Compartir informaci&oacute;n sobre el uso que haces de HearBound con otros usuarios de HearBound incluidos tus &ldquo;Seguidores&rdquo;.</p>
<p>Por ejemplo, los usuarios que te siguen como artista.</p>
</td>
</tr>
<tr>
<td valign="top" width="283">
<p>Artistas y discogr&aacute;ficas</p>
</td>
<td valign="top" width="283">
<p>Recibir noticias u ofertas promocionales directamente de artistas, discogr&aacute;ficas u otros socios.</p>
<p>Puedes elegir compartir datos personales (por ejemplo, tu direcci&oacute;n de correo electr&oacute;nico) para esta finalidad. Siempre tendr&aacute;s la opci&oacute;n de cambiar de opini&oacute;n y retirar tu consentimiento en cualquier momento.</p>
</td>
</tr>
</tbody>
</table>
<p><strong>Informaci&oacute;n que podemos compartir</strong></p>
<p>Consulta esta tabla para saber con qui&eacute;n compartimos y por qu&eacute;.</p>
<table border="1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td valign="bottom" width="157">
<p>Categor&iacute;as de destinatarios</p>
</td>
<td valign="bottom" width="409">
<p>Motivo para compartir</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Proveedores de servicios</p>
</td>
<td valign="top" width="409">
<p>Para que puedan prestar sus servicios a HearBound</p>
<p>Entre estos proveedores de servicios pueden encontrarse aquellos que contratamos para:</p>
<ul>
<li>prestar asistencia al cliente</li>
<li>operar la infraestructura t&eacute;cnica que necesitamos para prestar el Servicio de HearBound</li>
<li>ayudar a proteger y salvaguardar nuestros sistemas y servicios (por ejemplo, reCAPTCHA de Google)</li>
<li>ayudar a comercializar los productos, servicios, eventos y promociones de HearBound (y de nuestros socios)</li>
</ul>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Procesadores de pago</p>
</td>
<td valign="top" width="409">
<p>Para que puedan procesar sus pagos, y con fines de lucha contra el fraude.</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Socios publicitarios</p>
</td>
<td valign="top" width="409">
<p>Para que nuestros socios publicitarios puedan ayudarnos a ofrecerte una publicidad m&aacute;s pertinente en e HearBound.</p>
<p>Por ejemplo, nuestros socios publicitarios nos ayudan a ofrecer publicidad personalizada:</p>
<ul>
<li>es decir, cuando utilizamos informaci&oacute;n de terceros para adaptar los anuncios con el fin de que sean m&aacute;s pertinentes para ti (tambi&eacute;n conocida como publicidad basada en intereses).</li>
<li>Una publicidad personalizada es, por ejemplo, cuando un socio publicitario tiene informaci&oacute;n que sugiere que a ti te gustan los coches, lo que podr&iacute;a permitirnos mostrarte anuncios sobre autom&oacute;viles.</li>
<li>Si &ldquo;optas por no participar&rdquo; en la publicidad personalizada, es posible que sigas recibiendo publicidad en los servicios con publicidad basada en tu informaci&oacute;n de registro y anuncios basados en el contenido que est&aacute;s transmitiendo en nuestros Servicios.</li>
</ul>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Socios de marketing</p>
</td>
<td valign="top" width="409">
<p>Para promocionar HearBound con nuestros socios. Compartimos algunos datos personales con dichos socios cuando es necesario para:</p>
<ul>
<li>hacer posible que participes en promociones de HearBound, incluyendo pruebas u otras ofertas combinadas</li>
<li>promocionar HearBound en los medios de comunicaci&oacute;n y en la publicidad difundida en otros servicios en l&iacute;nea</li>
<li>ayudarnos a nosotros y a nuestros socios a medir la eficacia de las promociones de HearBound</li>
</ul>
<p>Entre los socios se incluyen, entre otros, los siguientes:</p>
<ul>
<li>socios de marketing o patrocinio</li>
<li>sitios web y aplicaciones m&oacute;viles que nos venden espacios publicitarios en sus servicios</li>
<li>socios de dispositivos, aplicaciones y m&oacute;viles que tambi&eacute;n ofrecen promociones de HearBound</li>
</ul>
<p>Nuestros socios tambi&eacute;n pueden combinar los datos personales que compartimos con ellos con otros datos que recopilan sobre ti (por ejemplo, tu uso de los servicios). Nosotros y nuestros socios podremos utilizar esta informaci&oacute;n para presentarte ofertas, promociones u otras actividades de marketing que consideremos relevantes para ti.</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Otros socios con los que compartimos informaci&oacute;n</p>
</td>
<td valign="top" width="409">
<p>Podemos compartir datos personales con socios para ayudarnos a entender y mejorar el rendimiento de nuestros productos y asociaciones.</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Investigadores acad&eacute;micos</p>
</td>
<td valign="top" width="409">
<p>Para actividades como el an&aacute;lisis estad&iacute;stico y el estudio acad&eacute;mico, pero &uacute;nicamente en un formato seudonimizado. Los datos seudonimizados son aquellos en los que tus datos se identifican mediante un c&oacute;digo en lugar de tu nombre u otra informaci&oacute;n que te identifique directamente.</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Otras empresas del grupo HearBound</p>
</td>
<td valign="top" width="409">
<p>Donde tenemos oficinas, para llevar a cabo nuestras operaciones comerciales diarias y para poder mantener y proporcionarte nuestro Servicio.</p>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Cuerpos de seguridad y otras autoridades</p>
</td>
<td valign="top" width="409">
<p>Cuando creamos de buena fe que es necesario que lo hagamos, por ejemplo, para:</p>
<ul>
<li>cumplir una obligaci&oacute;n legal</li>
<li>actuar en respuesta a un proceso legal v&aacute;lido (como una orden de registro, una orden judicial o una citaci&oacute;n)</li>
<li>en inter&eacute;s propio o de un tercero, en relaci&oacute;n con:
<ul>
<li>la seguridad nacional</li>
<li>el cumplimiento de la ley</li>
<li>litigios (un proceso judicial)</li>
<li>una investigaci&oacute;n penal</li>
<li>la protecci&oacute;n de la seguridad de cualquier persona</li>
<li>prevenci&oacute;n de una muerte o de un da&ntilde;o corporal inminente.</li>
</ul>
</li>
</ul>
</td>
</tr>
<tr>
<td valign="top" width="157">
<p>Compradores de nuestros negocios</p>
</td>
<td valign="top" width="409">
<p>Cuando vendamos o negociemos la venta de nuestro negocio a un comprador o posible comprador.</p>
<p>En este caso, HearBound continuar&aacute; garantizando la confidencialidad de tus datos personales. Te informaremos antes de que tus datos personales sean transferidos al comprador o de que comience a aplicarse una pol&iacute;tica de privacidad diferente.</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h1>Pol&iacute;tica de Cookies</h1>
<p>Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una informaci&oacute;n que se almacena en tu navegador web. Utilizamos cookies y dispositivos similares para facilitar tu navegaci&oacute;n en la Web, conocer c&oacute;mo interact&uacute;as con HearBound y, en determinados casos, poder mostrarte publicidad en funci&oacute;n de tus h&aacute;bitos de navegaci&oacute;n.</p>
<p>En la p&aacute;gina Pol&iacute;tica de Cookies puedes consultar toda la informaci&oacute;n relativa a la pol&iacute;tica de recogida, la finalidad y el tratamiento de las cookies.</p>
<h1>Conservaci&oacute;n y eliminaci&oacute;n de datos personales</h1>
<p><strong>Conservaci&oacute;n</strong></p>
<p>Conservamos tus datos personales &uacute;nicamente durante el tiempo necesario para proporcionarte el Servicio de HearBound y para los fines comerciales leg&iacute;timos y esenciales de HearBound como:</p>
<ul>
<li>mantener el rendimiento del Servicio de HearBound</li>
<li>tomar decisiones comerciales basadas en datos sobre nuevas funciones y ofertas</li>
<li>cumplir nuestras obligaciones legales</li>
<li>resolver controversias</li>
</ul>
<p>Solo se mantendran los datos de uso que permiten a los artistas obtener&nbsp; las estad&iacute;sticas que les ofrecemos sobre el desempe&ntilde;o del contenido que suben, si bien estos datos formar&aacute;n parte de las estad&iacute;sticas de manera totalmente an&oacute;nima y despersonificada.</p>
<p><strong>Eliminaci&oacute;n</strong></p>
<p>Si cierras o solicitas que cerremos tu cuenta, eliminaremos o anonimizaremos tus datos personales para que ya no te identifiquen, a menos que estemos obligados a conservar alg&uacute;n dato o que sigamos necesitando utilizarlo por una raz&oacute;n jur&iacute;dicamente justificable, y se mantendr&aacute;n durante el tiempo que determine la ley.</p>
<p>Estos son algunos ejemplos de situaciones en las que la ley nos permite u obliga a conservar algunos de tus datos personales:</p>
<ul>
<li>si hay un problema sin resolver relacionado con tu cuenta, como un abono pendiente o una reclamaci&oacute;n o controversia sin resolver</li>
<li>para cumplir con nuestras obligaciones legales, fiscales, de auditor&iacute;a y contables</li>
<li>para nuestros intereses comerciales leg&iacute;timos, tales como la prevenci&oacute;n del fraude o el mantenimiento de la seguridad.</li>
</ul>
<p>Si cancela cuenta, usted podr&aacute;:</p>
<ul>
<li>Mantener el consentimiento para desarrollar acciones comerciales: trataremos sus datos para las acciones comerciales que haya consentido. Consideraremos que ha elegido esta opci&oacute;n si no revoca el consentimiento expresamente.</li>
<li>Revocar el consentimiento para desarrollar acciones comerciales: cancelaremos sus datos mediante el bloqueo de los mismos.</li>
</ul>
<h1>Seguridad de los datos personales</h1>
<p>Para proteger tus datos personales, HearBound toma todas las precauciones razonables y sigue las mejores pr&aacute;cticas de la industria para evitar su p&eacute;rdida, mal uso, acceso indebido, divulgaci&oacute;n, alteraci&oacute;n o destrucci&oacute;n de los mismos, de acuerdo a esta Pol&iacute;tica de Privacidad. El sitio Web est&aacute; alojado en&nbsp;PROVEEDOR DE ALOJAMIENTO WEB. Desgraciadamente, el servicio no garantiza ser 100% seguro, y no podemos garantizar la seguridad de cualquier informaci&oacute;n que nos proporcione.</p>
<p><strong>Menores</strong></p>
<p>HearBound tiene un "l&iacute;mite de edad" m&iacute;nimo en cada pa&iacute;s. HearBound no est&aacute; dirigido a menores cuya edad:</p>
<ul>
<li>sea inferior a 14 a&ntilde;os</li>
<li>o, haga ilegal el tratamiento de sus datos personales</li>
<li>o, requiera el consentimiento de los padres para el tratamiento de sus datos personales</li>
</ul>
<p>No recopilamos de forma consciente datos personales de menores por debajo del L&iacute;mite de edad aplicable. Si tienes menos del l&iacute;mite de edad, no utilices HearBound ni nos proporciones ning&uacute;n dato personal.</p>
<p>Si eres padre o madre de un ni&ntilde;o/a menor de la edad l&iacute;mite y tienes conocimiento de que tu hijo/a ha proporcionado datos personales a HearBound, ponte en contacto con nosotros.</p>
<p>Si descubrimos que hemos recopilado datos personales de un menor de edad por debajo del l&iacute;mite de edad aplicable, adoptaremos medidas adecuadas para eliminar dichos datos. Esto puede requerir la eliminaci&oacute;n de la cuenta de HearBound del menor.</p>
<h1>Exactitud y veracidad de los datos personales</h1>
<p>Te comprometes a que los datos facilitados a HearBound sean correctos, completos, exactos y vigentes, as&iacute; como a mantenerlos debidamente actualizados.</p>
<p>Como Usuario del sitio Web eres el &uacute;nico responsable de la veracidad y correcci&oacute;n de los datos que remitas al sitio exonerando a HearBound de cualquier responsabilidad al respecto.</p>
<h1>Aceptaci&oacute;n y consentimiento</h1>
<p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protecci&oacute;n de datos de car&aacute;cter personal, aceptas y consientes el tratamiento de los mismos por parte de HearBound en la forma y para las finalidades indicadas en esta Pol&iacute;tica de Privacidad.</p>
<p><strong>Transferencias a otros paises </strong></p>
<p>Al llevar a cabo las actividades descritas en la presente Pol&iacute;tica, HearBound comparte tus datos personales a nivel internacional con empresas del grupo HearBound, subcontratistas y socios. Estos podr&aacute;n tratar tus datos en pa&iacute;ses cuya legislaci&oacute;n en materia de protecci&oacute;n de datos no se considere tan estricta como la de la UE o la que se aplica en tu lugar de residencia. En este sentido, es posible que no te otorguen los mismos derechos sobre tus datos.</p>
<p>Siempre que transferimos datos personales a nivel internacional, utilizamos herramientas destinadas a:</p>
<ul>
<li>garantizar que la transferencia de datos cumple la legislaci&oacute;n aplicable</li>
<li>ayudar a que tus datos tengan el mismo nivel de protecci&oacute;n que en la UE</li>
</ul>
<p>A tal efecto, utilizamos una serie de protecciones, adecuadas a cada transferencia de datos. Por ejemplo, utilizamos:</p>
<ul>
<li>cl&aacute;usulas contractuales est&aacute;ndar (o una herramienta legal alternativa) para exigir al tercero que proteja tus datos y te proporcione derechos y protecciones a nivel de la UE</li>
<li>protecciones t&eacute;cnicas, como el cifrado y la seudonimizaci&oacute;n</li>
<li>pol&iacute;ticas y procesos para impugnar las solicitudes desproporcionadas o ilegales de las autoridades gubernamentales</li>
</ul>
<p>Puedes ejercer tus derechos en virtud de las cl&aacute;usulas contractuales est&aacute;ndar poni&eacute;ndote en contacto con nosotros o con el tercero que trata tus datos personales.</p>
<h1>Usuarios internacionales</h1>
<p>Si visita este servicio desde fuera de la Uni&oacute;n Europa o de otras regiones con leyes referentes a la protecci&oacute;n de datos, tenga en cuenta que est&aacute; aceptando transferir sus datos a Espa&ntilde;a y procesarlo globalmente. Dando su informaci&oacute;n a HearBound, usted consiente cualquier transferencia y procesamiento de datos de acuerdo a lo establecido en esta Pol&iacute;tica de Privacidad.</p>
<h1>Cambios en la Pol&iacute;tica de Privacidad</h1>
<p>HearBound se reserva el derecho de modificar y/o actualizar la informaci&oacute;n sobre protecci&oacute;n de datos cuando sea necesario para el correcto cumplimiento del Reglamento de Protecci&oacute;n de Datos.</p>
<p>Si realizamos cambios sustanciales a esta Pol&iacute;tica, te lo notificaremos de forma adecuada en funci&oacute;n de las circunstancias. Por ejemplo, podremos enviarte una notificaci&oacute;n por correo electr&oacute;nico.</p>
<p><strong>Canal de comunicaci&oacute;n y soporte</strong></p>
<p>Las personas interesadas podr&aacute;n comunicar cualquier duda sobre el tratamiento de sus datos de car&aacute;cter personal o interpretaci&oacute;n de nuestra pol&iacute;tica en la siguiente direcci&oacute;n: ​ contacto@hearbound.com</p>
      </div>
      <Footer/>
      </div>
    )
}

export default Privacy;