import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Containers/Home/Home.js";
import Usuarios from "./Containers/Home/Usuarios/Usuarios";
import Artistas from "./Containers/Home/Artistas/Artistas";
import Conditions from "./Containers/Condiciones/Conditions";
import Cookies from './Containers/Cookies/Cookies';
import Privacy from "./Containers/Privacidad/Privacy";
import NotFound from "./NotFound";

import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/artistas" element={<Artistas />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/*" element={<NotFound />} />

      </Routes>
      <CookieConsent style={{ background: "#2B373B" }} flipButtons>Esta web usa cookies para mejorar la experiencia del usuario.</CookieConsent>
    </Router>
  );
}

export default App;
