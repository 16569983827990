import { useState, useEffect } from "react";
import Navbar from "../../../Components/NavBar/NavBar";
import Footer from "../../../Components/Footer/Footer";
import Alcance from "../../../Images/Alcancedetumano.gif";
import Astronauta from "../../../Images/astronauta.png";
import TusReglas from "../../../Images/MusicaUnica.svg";
import "./Usuarios.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Usuarios = () => {
  AOS.init();
  const [offset, setOffset] = useState(0);
  let o = "translateX(" + -offset / 2 + "px)";

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <>
      <div className="mainUsers">
        <Navbar />
        <div className="firstUsers">
          <div
            className="firstLeftUsers"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h1 className="firstUsersTitle">
              ¿CANSADO DE ESCUCHAR SIEMPRE LO MISMO?
            </h1>
            {/* <h2 className="firstUsersSubtitle">
              Nunca ha sido tan fácil descubrir música...
            </h2> */}
          </div>
          <div
            className="firstRightUsers"
            data-aos="fade-right"
            data-aos-delay="600"
          >
            <img className="firstUsersImage" src={TusReglas} alt=''/>
          </div>
        </div>

        <div className="secondUsers">
          <div
            className="secondLeftContainerUsers"
            style={{ backgroundColor: "#0f2c2b" }}
          >
            <div
              className="secondLeftUsers"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <img src={Alcance} className="imgStyle" alt=''/>
            </div>
          </div>
          <div
            className="secondRightContainerUsers"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="secondRightUsers"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="secondUsersTitle">
                Al alcance de tu <span className="spanTitleUsers">mano.</span>
              </h2>
              <p className="secondUsersText">
                HearBound es una app que te permite descubrir música que no
                encontrarás en ningún otro sitio cómodamente.
              </p>
              <p className="secondUsersText">
                Una vez hayas elegido los estilos que más te gusten, disfruta de
                descubrir canciones únicas videoclip tras videoclip, tal y como
                lo harías en TikTok. Una plataforma pensada para personas
                cansadas de escuchar siempre lo mismo.
              </p>
            </div>
          </div>
        </div>
        <section
          className="second slideTextUsers"
          style={{
            backgroundColor: "#0f2c2b",
          }}
        >
          <div className="overflow">
            <p style={{ transform: o, scrollBehavior: "smooth" }}>
              <span
                className="slideText slideTextUsers"
                style={{
                  opacity: "1",
                  // WebkitTextStrokeColor: "#0f2c2b",
                  // WebkitTextStrokeWidth: "2px",
                }}
              >
                ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y
                QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ
                MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS? ¿Y QUÉ MÁS?
              </span>
            </p>
          </div>
        </section>
        <div className="thirdUsers">
          <div
            className="secondLeftContainerUsers"
            style={{ backgroundColor: "#0f2c2b" }}
          >
            <div
              className="thirdLeftUsers"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="secondUsersTitle" style={{ color: "#fff347" }}>
                Descubre, Descubre y Descubre...
              </h2>
              <p className="secondUsersText" style={{ color: "#F9F9F9" }}>
                Toda la música que escuches será siempre única. Nunca escucharás
                dos veces los mismo. Y lo mejor, podrás llevar tu música a donde
                quieras fácilmente, ¿cómo?
              </p>
              <p className="secondUsersText" style={{ color: "#F9F9F9" }}>
                Una vez descubras una canción que te guste, le darás "like" para
                guardarla en tu biblioteca y exportarla a tu plataforma de
                streaming favorita para seguir disfrutándola.
              </p>
            </div>
          </div>
          <div
            className="secondRightContainerUsers"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="thirdRightUsers"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <img src={Astronauta} className="imgStyle" alt=''/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Usuarios;
