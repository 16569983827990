import Navbar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import "../Condiciones/Conditions.css";

const Cookies = () => {
  return (
    <div className="mainCondition">
      <Navbar />
      <div className="subMainCondition">
                <h2>Pol&iacute;tica de cookies</h2>
            <p><strong>&nbsp;</strong></p>
            <p>HearBound informa acerca del uso de las cookies en su p&aacute;gina web: https://www.hearbound.com/</p>
            
            <h2>&iquest;Qu&eacute; son las cookies?</h2>
            <p><strong>&nbsp;</strong></p>
            <p>Las cookies son archivos que se pueden descargar en su equipo a trav&eacute;s de las p&aacute;ginas web. Son herramientas que tienen un papel esencial para la prestaci&oacute;n de numerosos servicios de la sociedad de la informaci&oacute;n. Entre otros, permiten a una p&aacute;gina web almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo y, dependiendo de la informaci&oacute;n obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.</p>
            
            <h2>Tipos de cookies</h2>
            <p><strong>&nbsp;</strong></p>
            <p>Seg&uacute;n quien sea la entidad que gestione el dominio desde donde se env&iacute;an las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:</p>
            
            <ul>
            <li>Cookies propias: aqu&eacute;llas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
            
            <li>Cookies de terceros: aqu&eacute;llas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos trav&eacute;s de las cookies.</li>
        
            </li>
            </ul>
            
            <p>En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor, pero la informaci&oacute;n que se recoja mediante &eacute;stas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.</p>
            
            <p>Existe tambi&eacute;n una segunda clasificaci&oacute;n seg&uacute;n el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:</p>
            
            <ul>
            <li>Cookies de sesi&oacute;n: dise&ntilde;adas para recabar y almacenar datos mientras el usuario accede a una p&aacute;gina web. Se suelen emplear para almacenar informaci&oacute;n que solo interesa conservar para la prestaci&oacute;n del servicio solicitado por el usuario en una sola ocasi&oacute;n (p.e. una lista de productos adquiridos).
            
            <li>Cookies persistentes: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios a&ntilde;os.</li>
            
            </li>
            </ul>
            
            <p>Por &uacute;ltimo, existe otra clasificaci&oacute;n con cinco tipos de cookies seg&uacute;n la finalidad para la que se traten los datos obtenidos:</p>
            
            <ul>
            <li>Cookies t&eacute;cnicas: aquellas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web,</li>
            </ul>
            <p>plataforma o aplicaci&oacute;n y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar
            la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de v&iacute;deos o sonido o compartir contenidos a trav&eacute;s de redes sociales.</p>
            <ul>
            <li>Cookies de personalizaci&oacute;n: permiten al usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, etc.</li>
            <li>Cookies de an&aacute;lisis: permiten al responsable de las mismas, el seguimiento y an&aacute;lisis del comportamiento de los usuarios de los sitios web a los que est&aacute;n vinculadas. La informaci&oacute;n recogida mediante este tipo de cookies se utiliza en la medici&oacute;n de la actividad de los sitios web, aplicaci&oacute;n o plataforma y para la elaboraci&oacute;n de perfiles de navegaci&oacute;n de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en funci&oacute;n del an&aacute;lisis de los datos de uso que hacen los usuarios del servicio.</li>
            <li>Cookies publicitarias: permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios.
            <ul>
            <li>Cookies de publicidad comportamental: almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</li>
            <li>Cookies de redes sociales externas: se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (facebook, youtube, twitter, linkedIn, etc..) y que se generen &uacute;nicamente para los usuarios de dichas redes sociales. Las condiciones de utilizaci&oacute;n de estas cookies y la informaci&oacute;n recopilada se regula por la pol&iacute;tica de privacidad de la plataforma social correspondiente.</li>
            </ul>
            </li>
            </ul>
            
            <h2>Desactivaci&oacute;n y eliminaci&oacute;n de cookies</h2>
            <p><strong>&nbsp;</strong></p>
            <p>Tienes la opci&oacute;n de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuraci&oacute;n de las opciones del navegador instalado en su equipo. Al desactivar cookies, algunos de los servicios disponibles podr&iacute;an dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el men&uacute; Herramientas u Opciones. Tambi&eacute;n puede consultarse el men&uacute; de Ayuda del navegador d&oacute;nde puedes encontrar instrucciones. El usuario podr&aacute; en cualquier momento elegir qu&eacute; cookies quiere que funcionen en este sitio web.</p>
            
            <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las opciones del navegador instalado en su ordenador:</p>
            
            <ul>
            <li>Microsoft Internet Explorer o Microsoft Edge: <a href="http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies</a></li>
            <li>Mozilla Firefox: <a href="http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia">http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</a></li>
            <li>Chrome: https://support.google.com/accounts/answer/61416?hl=es</li>
            <li>Safari: <a href="http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/">http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/</a></li>
            <li>Opera: <a href="http://help.opera.com/Linux/10.60/es-ES/cookies.html">http://help.opera.com/Linux/10.60/es-ES/cookies.html</a></li>
            </ul>
           
            <p>Adem&aacute;s, tambi&eacute;n puede gestionar el almac&eacute;n de cookies en su navegador a trav&eacute;s de herramientas como las siguientes</p>
            <ul>
            <li>Ghostery: <a href="http://www.ghostery.com/">www.ghostery.com/</a></li>
            <li>Your online choices: <a href="http://www.youronlinechoices.com/es/">www.youronlinechoices.com/es/</a></li>
            </ul>
            
            <h2>Cookies utilizadas en https://www.hearbound.com/</h2>
            <p><strong>&nbsp;</strong></p>
            <p>A continuaci&oacute;n se identifican las cookies que est&aacute;n siendo utilizadas en este portal as&iacute; como su tipolog&iacute;a y funci&oacute;n:</p>
            
            <p>Usamos cookies de seguimiento, que almacenan informaci&oacute;n sobre los usuarios y que permite analizar el comportamiento de un usuario en la p&aacute;gina.</p>
            
            <p>Cookies propias:</p>
            
            <p>Ej: <em>cookie PHPSESSID &ndash; permite al usuario visualizar la p&aacute;gina web e interactuar con ella".</em></p>
            <p><em>&nbsp;</em></p>
            <p>Cookies de terceros:</p>
            <p><em>&nbsp;</em></p>
            <p><em>Google Analytics</em></p>
            <h2>Aceptaci&oacute;n de la Pol&iacute;tica de cookies</h2>
            <p><strong>&nbsp;</strong></p>
            <p><a href="https://www.hearbound.com/">https://www.hearbound.com/</a> asume que usted acepta el uso de cookies. No obstante, muestra informaci&oacute;n sobre su Pol&iacute;tica de cookies en la parte inferior o superior de cualquier p&aacute;gina del portal con cada inicio de sesi&oacute;n con el objeto de que usted sea consciente.</p>
            
            <p>Ante esta informaci&oacute;n es posible llevar a cabo las siguientes acciones:</p>
            
            <ul>
            <li>Aceptar cookies. No se volver&aacute; a visualizar este aviso al acceder a cualquier p&aacute;gina del portal durante la presente sesi&oacute;n.</li>
            <li>Cerrar. Se oculta el aviso en la presente p&aacute;gina.</li>
            </ul>
      </div>
      <Footer/>
      </div>
  
  );
};

  export default Cookies;