import { useState, useEffect } from "react";
import "./Home.css";
import Navbar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import PCPhone from "../../Images/PCIphone.png";
import Descubrimiento from "../../Images/1.png";
import Generos from "../../Images/2.png";
import Musica from "../../Images/3.png";
import Lugar from "../../Images/4.png";
import AOS from "aos";
import "aos/dist/aos.css";


const Home = () => {
  AOS.init();
  const [offset, setOffset] = useState(0);
  let o = "translateX(" + -offset / 2 + "px)";

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div className="main">
        <Navbar />
        <section className="first">
          <div className="firstLeft" data-aos="fade-up" data-aos-delay="300">
            <span className="firstSpan">¿Qué es HearBound?</span>
            <h1 className="firstTitle">
              HearBound es el “TikTok” para descubrir música. Descubre música
              videoclip tras videoclip en una plataforma pensada exclusivamente
              para ello.
            </h1>
            <button className="firstButton">Prueba gratis</button>
          </div>
          <div
            className="firstRight"
            data-aos="fade-right"
            data-aos-delay="600"
          >
            <img src={PCPhone} className="firstImg" alt=''/>
          </div>
        </section>
        <section className="second">
          <div className="overflow">
            <p style={{ transform: o, scrollBehavior: "smooth" }}>
              <span className="slideText">
                ¿POR QUÉ HEARBOUND? ¿POR QUÉ HEARBOUND? ¿POR QUÉ HEARBOUND? ¿POR
                QUÉ HEARBOUND?
              </span>
            </p>
          </div>
        </section>
        <section className="third">
          <div className="thirdRow" data-aos="fade-right" data-aos-delay="300">
            <div className="thirdRowTextContainer">
              <h2 className="thirdTitle">
                <span className="number">1</span>Por el descubrimiento
              </h2>
              <p className="thirdText">
                Una plataforma pensada para personas que buscan descubrir música
                nueva, y un espacio para que artistas menos conocidos crezcan y
                sean descubiertos.
              </p>
            </div>
            <div className="thirdRowImageContainer">
              <img src={Descubrimiento} className="thirdImg" alt=''/>
            </div>
          </div>
          <div
            className="thirdRowInversed"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="thirdRowTextContainer">
              <div className="thirdRowTextContainerNoSpan">
                <h2 className="thirdTitle">
                  <span className="number">2</span>Para todos los géneros
                </h2>
                <p className="thirdText">
                  En HearBound tenemos en cuenta todos los gustos para que los
                  oyentes encuentren todo lo que buscan, y todos los artistas
                  puedan ser encontrados.
                </p>
              </div>
            </div>
            <div className="thirdRowImageContainer">
              <img src={Generos} alt=''/>
            </div>
          </div>
          <div className="thirdRow" data-aos="fade-right" data-aos-delay="300">
            <div className="thirdRowTextContainer">
              <h2 className="thirdTitle">
                <span className="number red">3</span>Tu música, donde quieras
              </h2>
              <p className="thirdText">
                Una vez los usuarios descubran tu música, la guardarán en la
                plataforma de streaming que tu elijas para seguir escuchándola,
                dejando espacio únicamente a canciones nuevas por descubrir.
              </p>
            </div>
            <div className="thirdRowImageContainer">
              <img src={Musica} alt=''/>
            </div>
          </div>
          <div
            className="thirdRowInversed"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="thirdRowTextContainer">
              <div className="thirdRowTextContainerNoSpan">
                <h2 className="thirdTitle">
                  <span className="number">4</span>Un lugar único
                </h2>
                <p className="thirdText">
                  HearBound está lleno de música única que no descubrirás en
                  ningún otro sitio, pensada para que todos los artistas tengan
                  un lugar donde brillar.
                </p>
              </div>
            </div>
            <div className="thirdRowImageContainer">
              <img src={Lugar} alt=''/>
            </div>
          </div>
        </section>
        <section className="forth">
          <p className="forthText">Y lo mejor... HearBound es GRATUITO!!</p>
          <p className="forthText">
            Tanto para artistas como para oyentes. Que sí, que sí, no estás
            soñando
          </p>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Home;
