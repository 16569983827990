import "./Footer.css";
import {ReactComponent as Instagram} from '../../Images/instagram.svg'
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
 
  let handleClick = () => {
    let path = "https://www.instagram.com/hearbound/";
    window.location.href = path;
  };

  return (
    <footer>
      <div className="footer">
        <div className="routeFooter">
          <div onClick={() => navigate("/privacy")}>Privacidad</div>
          <div onClick={() => navigate("/conditions")}>
            Terminos y Condiciones
          </div>
          <div onClick={() => navigate("/cookies")}>Cookies</div>
        </div>
        <div className="midFooter">
          <div className="socialFooter">
            <div className="socialText">
              Síguenos en Instagram
            </div>
            <div className="socialImages">
    
                            <Instagram onClick={handleClick} className='instagram'/>
            </div>
          </div>
        </div>
        <div className="contactFooter">
          <p>¿Necesitas hablar con nosotros? Escríbenos en:</p>
            <p className="contactFooterEmail">contacto@hearbound.com</p>
          
        </div>
      </div>
      <div>
        <div className="textFooter">2022 HearBound</div>
      </div>
    </footer>
  );
};

export default Footer;
