import Navbar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import "./Conditions.css";

const Conditions = () => {
  return (
    <div className="mainCondition">
      <Navbar />
      <div className="subMainCondition">
      <h1>T&eacute;rminos y Condiciones de Uso de HearBound</h1>
<p>Bienvenido a Hearbound.&reg; (en adelante, "HearBound", "nosotros", "nos" o "nuestro").</p>
<p>Estos T&eacute;rminos y Condiciones de Uso regulan el uso por su parte de HearBound.com (la "P&aacute;gina Web") y nuestras aplicaciones para m&oacute;vil o de escritorio (en el conjunto, la &ldquo;Plataforma&rdquo; o las &ldquo;Apps&rdquo;)</p>
<p>Estos T&eacute;rminos y Condiciones de Uso constituye un contrato legalmente vinculante (el "Contrato") entre usted y HearBound en relaci&oacute;n con el uso por su parte de la P&aacute;gina Web y la Plataforma, la App y los Servicios</p>
<p>Por favor, aseg&uacute;rese de revisar nuestra Pol&iacute;tica de Privacidad y nuestra Pol&iacute;tica de Cookies para obtener m&aacute;s informaci&oacute;n de c&oacute;mo recopilamos y usamos los datos relativos al uso y desarrollo de la Plataforma, as&iacute; como de nuestra responsabilidad y sus derechos en relaci&oacute;n al tratamiento de sus datos personales.</p>
<p>Estos T&eacute;rminos y Condiciones de Uso consisten en las siguientes secciones:</p>
<ul>
<li><strong>Aceptaci&oacute;n de los T&eacute;rminos y Condiciones de Uso:</strong> b&aacute;sicamente, al usar HearBound usted acepta nuestros a los T&eacute;rminos y Condiciones de Uso y est&aacute; de acuerdo en atenerse a las mismas.</li>
<li><strong>Cambios en los T&eacute;rminos y Condiciones de Uso:</strong> esta secci&oacute;n explica que nuestros T&eacute;rminos y Condiciones de Uso pueden cambiar ocasionalmente.</li>
<li><strong>Descripci&oacute;n de la Plataforma:</strong> proporciona una descripci&oacute;n general de la Plataforma, sus caracter&iacute;sticas y sus funcionalidades.</li>
<li><strong>Su Cuenta HearBoun</strong>d: esta secci&oacute;n explica sus responsabilidades en caso de que decidas registrar una cuenta HearBound.</li>
<li><strong>Su Uso de la Plataform</strong>a: esta secci&oacute;n expone su derecho a usar la Plataforma, y las condiciones aplicables al uso por su parte de la Plataforma.</li>
<li><strong>Sus Contenidos: </strong>esta secci&oacute;n trata de la titularidad de su contenido, e incluye su aceptaci&oacute;n de no subir nada que infrinja los derechos de cualquier tercero.</li>
<li><strong>Concesi&oacute;n de Licencia:</strong> esta secci&oacute;n explica c&oacute;mo se usar&aacute; su contenido en HearBound y los permisos que otorga mediante la subida de su contenido &ndash; por ejemplo, el derecho de otros usuarios a escuchar sus pistas/audio.</li>
<li><strong>Manifestaciones y Garant&iacute;as:</strong> esta secci&oacute;n incluye promesas y garant&iacute;as importantes que usted otorga cuando sube contenido a HearBound - en particular, su compromiso de que todo lo que suba y comparta es de su propiedad y no infringir&aacute; derechos de terceros.</li>
<li><strong>Responsabilidad por Contenido:</strong> esta secci&oacute;n explica que HearBound es un servicio de almacenamiento y que sus usuarios son los &uacute;nicos y exclusivamente responsables por el material que suben a HearBound.</li>
<li><strong>Denuncia de infracciones:</strong> esta secci&oacute;n explica c&oacute;mo notificarnos de contenidos en HearBound que creas que infringen sus derechos de autor o cualquier otro derecho de propiedad intelectual, o que sea ilegal, abusivo, difamatorio o de cualquier otro modo contrario a nuestros T&eacute;rminos y Condiciones de Uso o a nuestras Normas de Copyright. Puedes encontrar informaci&oacute;n adicional sobre c&oacute;mo denunciar infracciones de propiedad intelectual en nuestras p&aacute;ginas relativas a las Normas de Copyright.</li>
<li><strong>P&aacute;ginas web y servicios de terceros:</strong> a trav&eacute;s de HearBound puedes tener acceso a otras p&aacute;ginas web y otros servicios. Esta secci&oacute;n explica que &eacute;stos son servicios independientes de terceros que no est&aacute;n bajo el control de HearBound.</li>
<li><strong>Bloqueo y retirada de contenido:</strong> esta secci&oacute;n deja claro que HearBound puede bloquear o retirar contenido de la Plataforma.</li>
<li><strong>Infractores reincidentes:</strong> usuarios que infrinjan de forma reiterada derechos de terceros o incumplan nuestros T&eacute;rminos y Condiciones de Uso se arriesgan a que sus cuentas de HearBound sean suspendidas o canceladas, como se explica en esta secci&oacute;n.</li>
<li><strong>Descargo de Responsabilidad:</strong> esta secci&oacute;n explica que HearBound no puede ofrecer ninguna garant&iacute;a de que la Plataforma estar&aacute; siempre disponible &ndash; a veces incluso una plataforma tan incre&iacute;ble como la nuestra puede tener alg&uacute;n problema.</li>
<li><strong>Limitaci&oacute;n de responsabilidad:</strong> esta secci&oacute;n explica algunas de esas cosas de las que HearBound no ser&aacute; responsable. Por favor, aseg&uacute;rate de que lees y comprendes esta secci&oacute;n.</li>
<li><strong>Indemnizaci&oacute;n:</strong> si utilizas las Plataforma de una forma que resulte en da&ntilde;os para nosotros, tendr&aacute;s que asumir responsabilidad por ello.</li>
<li><strong>Protecci&oacute;n de datos, Privacidad y Cookies:</strong> es realmente importante para nosotros que entiendas c&oacute;mo usaremos su informaci&oacute;n personal. Toda la informaci&oacute;n se recaba, almacena y utiliza de conformidad con nuestra Pol&iacute;tica de Privacidad, por lo que aseg&uacute;rate de leer y entender dicha pol&iacute;tica. Como muchas otras p&aacute;ginas web, utilizamos cookies para analizar c&oacute;mo utiliza HearBound la gente, de manera que podamos continuar mejorando nuestro servicio. Nuestro uso de cookies se explica en la Pol&iacute;tica de Cookies.</li>
<li><strong>Cambios en la Plataforma y cuentas: </strong>ocasionalmente, puede que necesitemos hacer algunos cambios en HearBound. Esta secci&oacute;n explica sus derechos en tal situaci&oacute;n.</li>
<li><strong>Finalizaci&oacute;n:</strong> esta secci&oacute;n explica c&oacute;mo puede cerrar su cuenta HearBound, y los motivos por los que nosotros podemos finalizar el uso por su parte de HearBound.</li>
<li><strong>Cesi&oacute;n a terceros</strong>: esta secci&oacute;n trata del derecho de HearBound a ceder este contrato a terceros.</li>
<li><strong>Divisibilidad: </strong>esta es una cl&aacute;usula legal standard, que establece que cualquier estipulaci&oacute;n que no sea v&aacute;lida se eliminar&aacute; del contrato sin afectar a la validez del resto del contrato.</li>
<li><strong>Contrato completo:</strong> el uso de HearBound por su parte est&aacute; gobernado por estos T&eacute;rminos y Condiciones de Uso, nuestra Pol&iacute;tica de Privacidad y Pol&iacute;tica de Cookies. Cualquier modificaci&oacute;n deber&aacute; hacerse por escrito.</li>
<li><strong>Derechos de terceros:</strong> estos T&eacute;rminos y Condiciones de Uso se aplican &uacute;nicamente a la relaci&oacute;n entre HearBound y t&uacute;.</li>
<li><strong>Ley Aplicable y Jurisdicci&oacute;n:</strong> todos nuestros documentos est&aacute;n por lo general sometidos a ley espa&ntilde;ola.</li>
<li><strong>Informaci&oacute;n:</strong> esta secci&oacute;n proporciona informaci&oacute;n sobre HearBound, incluyendo c&oacute;mo contactar con nosotros.</li>
</ul>
<h1>Aceptaci&oacute;n de los T&eacute;rminos y Condiciones de Uso</h1>
<p>Por favor, lea estos T&eacute;rminos y Condiciones de Uso, la Pol&iacute;tica de Privacidad y la Pol&iacute;tica de Cookies , muy atentamente. Si usted no est&aacute; de acuerdo con alguna de las disposiciones contenidas en dichos documentos, no deber&iacute;a usar la P&aacute;gina Web, las Apps ni ninguno de los Servicios. Mediante el mero acceso y el uso de la Plataforma, el registro de una cuenta, o mediante el visionado, acceso o subida de cualquier informaci&oacute;n o contenido a o de la Plataforma, usted est&aacute; manifestando y garantizando que ha le&iacute;do y entendido los T&eacute;rminos y Condiciones de Uso, que usted tiene 14 a&ntilde;os de edad o m&aacute;s y que usted acepta estas Condiciones y se compromete a cumplirlas.</p>
<h1>Cambios en los T&eacute;rminos y Condiciones de Uso</h1>
<p>Nos reservamos el derecho a cambiar, alterar, reemplazar o de cualquier otro modo modificar estos T&eacute;rminos y Condiciones de Uso en cualquier momento, por ejemplo, para abordar cambios legales o normativos o cambios de caracter&iacute;sticas, funciones o del funcionamiento puestas a disposici&oacute;n a trav&eacute;s de la Plataforma, a nuestra discreci&oacute;n. La fecha de la &uacute;ltima modificaci&oacute;n se indica al final de estos T&eacute;rminos y Condiciones de Uso. Es su responsabilidad revisar peri&oacute;dicamente esta p&aacute;gina para conocer las actualizaciones.</p>
<p>Cuando hagamos cualquier cambio material de estos T&eacute;rminos y Condiciones de Uso, le proporcionaremos un aviso destacado seg&uacute;n las circunstancias, incluyendo, por ejemplo, despliegue de un aviso dentro de la Plataforma y/o el env&iacute;o de un correo electr&oacute;nico a la direcci&oacute;n de correo que usted nos ha proporcionado. Los T&eacute;rminos y Condiciones de Uso revisados ser&aacute;n efectivos dos (2) semanas despu&eacute;s de tal notificaci&oacute;n.</p>
<p>No tendr&aacute; obligaci&oacute;n de continuar usando la Plataforma despu&eacute;s de recibir esta notificaci&oacute;n, pero si no cierra su cuenta tal como se describe m&aacute;s abajo en la secci&oacute;n Finalizaci&oacute;n durante el mencionado per&iacute;odo de dos (2) semanas, el uso continuado de la Plataforma despu&eacute;s de finalizar ese per&iacute;odo de dos (2) semanas constituir&aacute; su aceptaci&oacute;n de los T&eacute;rminos y Condiciones de Uso revisados.</p>
<h1>Descripci&oacute;n de la Plataforma</h1>
<p>La Plataforma es un servicio de almacenamiento y promoci&oacute;n musical en la que hay que diferenciar dos tipos de usuarios: los artistas y los oyentes.&nbsp; Los usuarios registrados de la Plataforma pueden escuchar, enviar, subir y postear audio, texto, fotograf&iacute;as, im&aacute;genes, ver gr&aacute;ficos y otro contenido, datos o informaci&oacute;n ("Contenidos"), que ser&aacute; almacenado por HearBound siguiendo las instrucciones de tales usuarios registrados, y podr&aacute; ser compartido y distribuido por dichos usuarios registrados y por otros usuarios de la Plataforma usando las herramientas y funciones que se proporcionan como parte de la Plataforma y que son accesibles a trav&eacute;s de las Apps. La Plataforma permite a cualquier usuario de las Apps a ver, escuchar y compartir los Contenidos subidos y puesto a disposici&oacute;n por usuarios registrados.</p>
<p>Las Apps adem&aacute;s incluye funciones sociales e interactivas que permiten a los oyentes seguir a los artistas de HearBound con el objetivo de que pueda crear su red de seguidores y asegurar que acceda al contenido que m&aacute;s le interese. Por ejemplo, los artistas que suban contenido a HearBound tendr&aacute;n acceso a nuestra funci&oacute;n de estad&iacute;sticas, el cual aporta a los creadores de informaci&oacute;n acerca de c&oacute;mo el contenido que han subido se desarrolla entre los usuarios.</p>
<p>Con el fin de hacer la Plataforma de gratis acceso y proveerle con relevantes y personalizadas funciones, presentamos anuncios adaptados en la Plataforma a cargo de terceros anunciantes y de los propios artistas. Con tal fin, usamos informaci&oacute;n que usted nos ha aportado cuando usted interact&uacute;a con la Plataforma para informarle de la naturaleza de los anuncios que le mostramos y de esta manera ofrecerle una experiencia personalizada. Para m&aacute;s informaci&oacute;n de c&oacute;mo usamos la informaci&oacute;n para mostrarle publicidad personalizada se encuentra descrita en nuestra Pol&iacute;tica de Privacidad.</p>
<p>Ocasionalmente, nosotros podremos sacar nuevas herramientas y recursos en la P&aacute;gina Web, sacar nuevas versiones o a&ntilde;adir otros servicios y/o funcionalidades para nuestras Apps. Todos los nuevos servicios y funciones estar&aacute;n sujetos a estos T&eacute;rminos y Condiciones de Uso, as&iacute; como a cualesquiera t&eacute;rminos y condiciones de uso adicionales que podamos sacar para dichos servicios o funciones espec&iacute;ficas.</p>
<h1>Su cuenta</h1>
<p>El acceso a la plataforma web para artistas, a la App y a ciertos Servicios est&aacute; disponible solo para usuarios registrados.</p>
<ul>
<li>Como artista, permite subir los Contenidos, poni&eacute;ndolo a disposici&oacute;n de otros usuarios para que lo escuchen. Tambi&eacute;n puede gestionar sus Contenidos en cualquier momento.</li>
<li>Como oyente, permite a los usuarios registrados escuchar el contenido subido por los artistas, interactuar con ese contenido, seguir a los artistas y escuchar nuevamente las canciones que se encuentren en su biblioteca para redirigirse a las plataformas donde quiera escuchar las canciones guardadas.</li>
</ul>
<p>Con el objetivo de conectarle con otros usuarios de HearBound, le facilitamos la creaci&oacute;n y personalizaci&oacute;n de un perfil en HearBound. Para permitirle este uso, usted debe de aportarnos la informaci&oacute;n que quiera mostrar en dicho perfil.</p>
<p>Para ayudarle a navegar y descubrir contenido que le interese en la Plataforma, como usuario registrado usted recibir&aacute; recomendaciones personalizada autogeneradas de otros contenidos que quiz&aacute;s le interese, de acuerdo con sus preferencias de escucha que usted mismo ha marcado.</p>
<p>Cuando se registre para usar la Plataforma, deber&aacute; proporcionarnos su direcci&oacute;n de correo electr&oacute;nico, y deber&aacute; elegir un nombre de usuario y una contrase&ntilde;a para su cuenta. Deber&aacute; tambi&eacute;n asegurarse de que la direcci&oacute;n de correo electr&oacute;nico que nos proporcione sea v&aacute;lida y continuar&aacute; si&eacute;ndolo. Su direcci&oacute;n de correo electr&oacute;nico y cualquier otra informaci&oacute;n que decida proporcionarnos sobre su persona ser&aacute; tratada de conformidad con nuestra Pol&iacute;tica de Privacidad.</p>
<p>Usted es el &uacute;nico responsable de mantener la confidencialidad y seguridad de su nombre de usuario y contrase&ntilde;a, as&iacute; como de cualquier uso de su nombre de usuario y de su contrase&ntilde;a, y de toda actividad que se derive de tu cuenta, haya sido dicha actividad autorizada por usted o no.</p>
<p>Si su nombre de usuario o contrase&ntilde;a se pierde o es robado, o si cree que terceros no autorizados han podido acceder a su cuenta, le recomendamos que lo notifique por escrito a HearBound, as&iacute; como que cambie su contrase&ntilde;a tan pronto como le sea posible.</p>
<p>Nos reservamos el derecho a rechazar, cancelar, eliminar o reasignar ciertos nombres de usuarios y enlaces en circunstancias apropiadas, seg&uacute;n lo determinemos nosotros a nuestro exclusivo criterio, y podremos, con o sin previo aviso, suspender o cancelar su cuenta si en dicha cuenta se producen actividades que, a nuestro criterio, constituyen o podr&iacute;an constituir una violaci&oacute;n de estos T&eacute;rminos y Condiciones de Uso, o una infracci&oacute;n o violaci&oacute;n de los derechos de cualquier tercero, o de cualquier ley o reglamento aplicable.</p>
<p>Usted podr&aacute; cerrar su cuenta en cualquier momento, tal como se describe m&aacute;s abajo en la secci&oacute;n Finalizaci&oacute;n.</p>
<h1>Su uso de la Plataforma</h1>
<p>Con sujeci&oacute;n al estricto cumplimiento por su parte de estos T&eacute;rminos y Condiciones de Uso en todo momento mientras use la Plataforma, si se registra para usar la Plataforma, HearBound le otorga una licencia limitada, personal, no exclusiva, revocable, intransmisible e intransferible, para usar la Plataforma para ver Contenidos subidos y posteados, para escuchar los Contenidos de audio desde la App&nbsp; y valorar y compartir los Contenidos de audio usando las herramientas de la Plataforma.</p>
<p>Adicionalmente, siempre con sujeci&oacute;n al estricto cumplimiento por tu parte de estos T&eacute;rminos y Condiciones de Uso en todo momento mientras use la Plataforma se le otorga una licencia limitada, personal, no exclusiva, revocable, intransmisible e intransferible, para:</p>
<ol>
<li>Enviar, subir o postear los Contenidos a/en la Plataforma, y a mantener tales Contenidos a accesible en la Plataforma estrictamente como se permite de acuerdo con estos T&eacute;rminos y Condiciones de Uso y cualesquiera otros t&eacute;rminos y condiciones aplicables que se posteen en la P&aacute;gina Web de tanto en tanto;</li>
<li>Utilizar la Plataforma Apps y otros Servicios que se proporcionan como parte de la Plataforma estrictamente como se permite de acuerdo con estos T&eacute;rminos y Condiciones de Uso y cualesquiera otros t&eacute;rminos y condiciones aplicables de tanto en tanto a dichas Apps o Servicios;</li>
</ol>
<p>Las licencias m&aacute;s arriba descritas est&aacute;n condicionadas al estricto cumplimiento por su parte de estos T&eacute;rminos y Condiciones de Uso en todo momento mientras usted use la Plataforma, incluyendo sin limitaci&oacute;n lo siguiente:</p>
<ol>
<li>No debe copiar o capturar, o intentar copiar o capturar, ning&uacute;n Contenidos de audio de la Plataforma ni parte alguna de la Plataforma.</li>
<li>No debe adaptar, copiar, volver a publicar, poner a disposici&oacute;n del p&uacute;blico o comunicar al p&uacute;blico de ninguna forma, exhibir, ejecutar, transferir, usar o explotar de ninguna manera ning&uacute;n Contenidos en o desde la Plataforma en ning&uacute;n momento, salvo cuando tal Contenido sea Sus Contenidos y en todo momento durante su uso de los Contenidos aplicable, o tal como se permite en estos T&eacute;rminos y Condiciones de Uso.</li>
<li>No debe usar ning&uacute;n Contenido (excepto Sus Contenidos) de forma dise&ntilde;ada para crear un servicio de contenidos separado o que de alguna forma replique cualquier parte de la oferta de la Plataforma.</li>
<li>No debe utilizar scraping o t&eacute;cnicas similares para agregar, reconvertir, volver a publicar o hacer cualquier otro uso de ning&uacute;n Contenido.</li>
<li>No debe utilizar ninguna t&eacute;cnica o hacer uso de ning&uacute;n servicio, autom&aacute;tico o de otro tipo, dise&ntilde;ado para tergiversar la popularidad de Sus Contenidos en la Plataforma, o para tergiversar su actividad en la Plataforma, incluyendo sin limitaci&oacute;n mediante el uso de bots, botnets, scripts, apps, plugins, extensiones o cualesquiera otros medios automatizados para registrar cuentas, acceder, reproducir Contenidos,&nbsp; o de cualquier otro modo actuar en su lugar, particularmente cuando tales actividades ocurran de forma m&uacute;ltiple o repetitiva. No debe ofrecer o promover la disponibilidad de ninguna de estas t&eacute;cnicas o servicios a otros usuarios de la Plataforma.</li>
<li>No debe alterar o eliminar, ni intentar alterar o eliminar, ninguna informaci&oacute;n relativa a la propiedad intelectual, ni ninguna otra informaci&oacute;n legal contenida en la Plataforma o que aparezca en ella, o en alg&uacute;n Contenidos que est&eacute; en la Plataforma (distinto de Sus Contenidos).</li>
<li>No debe copiar o adaptar el c&oacute;digo objeto de la P&aacute;gina Web o de ninguna de la Plataforma o la App o de los Servicios, ni hacer ingenier&iacute;a inversa, ensamblaje inverso, descompilar, modificar o intentar revelar c&oacute;digo fuente o c&oacute;digo objeto de parte alguna de la Plataforma, o eludir o copiar ning&uacute;n mecanismo de protecci&oacute;n de copiado o restricciones territoriales, ni acceder a informaci&oacute;n sobre gesti&oacute;n de derechos correspondiente a Contenidos distinto de Sus Contenidos, y no debe permitir que ning&uacute;n tercero lo haga.</li>
<li>No debe utilizar la Plataforma para subir, postear, almacenar, transmitir, exhibir, copiar, promocionar, poner a disposici&oacute;n, seguir poniendo a disposici&oacute;n o de cualquier otra forma comunicar al p&uacute;blico:<ol>
<li>ning&uacute;n Contenido que sea abusivo, calumnioso, difamatorio, pornogr&aacute;fico u obsceno, que promueva o incite a la violencia, al terrorismo, a acciones ilegales, o al odio por raz&oacute;n de raza, etnicidad, creencias religiosas, incapacidades, g&eacute;nero, identidad u orientaci&oacute;n sexual, o sea de otro modo cuestionable bajo el criterio razonable de HearBound;</li>
<li>ninguna informaci&oacute;n, Contenido o cualquier otro material que viole, plagie, se apropie inadecuadamente de o infrinja los derechos de terceros, incluyendo, sin limitaci&oacute;n, propiedad intelectual, derechos de marcas, derechos de intimidad o de imagen, informaci&oacute;n confidencial o cualquier otro derecho;</li>
<li>ning&uacute;n Contenido que viole, infrinja o sea contrario a alguna ley, norma, reglamento, mandato judicial o sea de cualquier otro modo ilegal o il&iacute;cito a juicio razonable de HearBound;</li>
<li>ning&uacute;n material de ning&uacute;n tipo que contenga alg&uacute;n virus, troyano, spyware, adware, malware, bot, time bomb, gusano, o cualquier otro componente da&ntilde;ino o malicioso, que sobrecargue, da&ntilde;e o deteriore o pueda sobrecargar, da&ntilde;ar o deteriorar, la Plataforma o los servidores o redes que forman parte de o est&aacute;n conectadas a la Plataforma, o que restrinja o inhiba o pueda restringir o inhibir el uso y disfrute de otros usuarios de la Plataforma; o</li>
<li>ning&uacute;n anuncio, mensaje promocional, spam o cualquier otra forma de incitaci&oacute;n que no se haya solicitado o autorizado, de acuerdo al presente T&eacute;rmino y condiciones de uso.</li>
<li>No debe cometer o participar en, ni animar, inducir, incitar o promover ninguna conducta que pueda constituir un delito, dar lugar a responsabilidad civil o de cualquier otro modo violar alguna ley o reglamento.</li>
<li>No debe alquilar, vender o prestar el acceso a las Apps ni ninguno de los Contenidos que est&eacute; en la Plataforma, aunque esto no impide que usted incluya enlaces de Sus Contenidos a cualquier tienda online de descargas leg&iacute;tima desde la que se pueda adquirir cualquier pieza de Sus Contenidos.</li>
<li>No debe hacerse pasar de forma deliberada por otra persona o entidad, o de otro modo falsear su vinculaci&oacute;n con alguna persona o entidad, por ejemplo, mediante el registro de una cuenta a nombre de otra persona o compa&ntilde;&iacute;a.</li>
<li>No debe acechar, explotar, amenazar, abusar o de otro modo acosar a ning&uacute;n otro usuario, ni a ning&uacute;n empleado de HearBound.</li>
<li>No debe usar o intentar usar la cuenta de otra persona, su contrase&ntilde;a ni ninguna otra informaci&oacute;n, salvo que dicha persona le haya dado permiso expresamente.</li>
<li>No debe vender o transferir, ni ofrecer vender o transferir, ninguna cuenta HearBound a ninguna otra persona sin la previa aprobaci&oacute;n por escrito de HearBound.</li>
<li>No debe recopilar ni intentar recopilar datos personales, ni ninguna otra clase de informaci&oacute;n sobre otros usuarios, incluyendo sin limitaci&oacute;n, mediante spidering o scraping.</li>
<li>&nbsp;No debe violar, eludir o intentar violar o eludir ninguna medida de seguridad de datos empleada por HearBound; acceder o intentar acceder a datos o a materiales que no est&aacute;n destinados a su uso; entrar en, o intentar entrar en, un servidor o una cuenta para cuyo acceso no est&eacute; autorizado; intentar escanear o probar la vulnerabilidad de los servidores, el sistema o la red de HearBound, ni intentar quebrar la seguridad de datos o los procedimientos de autenticaci&oacute;n de HearBound; intentar interferir con la P&aacute;gina Web o con los Servicios en forma alguna incluyendo, sin limitaci&oacute;n, piratear los servicios o los sistemas de HearBound, enviar un virus, sobrecargar, enviar correos-bomba o provocar errores graves. Sin limitaci&oacute;n de cualquier otro derecho o remedio que pueda corresponder a HearBound de acuerdo con estos T&eacute;rminos y Condiciones de Uso, HearBound se reserva el derecho a investigar cualquier situaci&oacute;n que parezca implicar cualquiera de las anteriores conductas, y podr&aacute; informar de tales asuntos a, y cooperar con, las autoridades policiales o de otro tipo apropiadas para procesar a cualquier usuario que haya participado en tales violaciones.</li>
</ol></li>
</ol>
<p>Usted acepta cumplir con estas condiciones en todo momento durante su uso de la Plataforma, y reconoce y acepta que HearBound tiene derecho, a su sola discreci&oacute;n, a cancelar su cuenta o a adoptar cualquier otra medida que consideremos oportuna si usted incumple cualquiera de estas condiciones o cualquier otra disposici&oacute;n de estos T&eacute;rminos y Condiciones de Uso. Ello puede incluir iniciar acciones legales y/o informar a las autoridades relevantes sobre los usuarios infractores.</p>
<h1>Sus Contenidos</h1>
<p>Todos y cada uno de los audios, textos, fotos, im&aacute;genes y dem&aacute;s contenido, datos o informaci&oacute;n que usted suba, almacene, transmita, env&iacute;e, intercambie o ponga a disposici&oacute;n de o a trav&eacute;s de la Plataforma (en Adelante, "Sus Contenidos") est&aacute; generado y controlado solo por usted, y no por HearBound, y es de su sola propiedad, y no de HearBound.</p>
<p>HearBound no reclama la titularidad de ning&uacute;n derecho sobre Sus Contenidos, y por la presente usted expresamente reconoce y acepta que Sus Contenidos quedan bajo su sola responsabilidad.</p>
<p>Sin perjuicio de las condiciones establecidas en &ldquo;Su Uso de la Plataforma&rdquo; usted no debe subir, almacenar, distribuir, enviar, transmitir, exhibir, ejecutar, poner a disposici&oacute;n o de otro modo comunicar al p&uacute;blico ning&uacute;n Contenidos sobre el que no ostente los derechos necesarios. En particular, cualquier uso no autorizado de material protegido por la propiedad intelectual en Sus Contenidos (incluyendo mediante su reproducci&oacute;n, distribuci&oacute;n, modificaci&oacute;n, adaptaci&oacute;n, exhibici&oacute;n p&uacute;blica, ejecuci&oacute;n p&uacute;blica, preparaci&oacute;n de obras derivadas, puesta a disposici&oacute;n u otras formas de comunicaci&oacute;n p&uacute;blica a trav&eacute;s de la Plataforma), con independencia de si no est&aacute; autorizado ahora o deviene no autorizado m&aacute;s adelante, puede ser constitutivo de una infracci&oacute;n de derechos de terceros y est&aacute; <em>estrictamente prohibida</em>. Cualquiera de las anteriores infracciones puede resultar en la cancelaci&oacute;n de su acceso a la Plataforma tal como se describe m&aacute;s abajo en la secci&oacute;n &ldquo;Infractores Reincidentes&rdquo;, y puede resultar asimismo en un litigio civil o en un procedimiento penal por parte de o por cuenta del titular de derechos afectado.</p>
<p>No todos los usos no autorizados de las obras protegidas por derechos de autor constituyen una vulneraci&oacute;n. Los usuarios de la Uni&oacute;n Europea pueden usar obras protegidas por derechos de autor de acuerdo con ciertas excepciones o limitaciones a los derechos de autor, como cuando dichas obras se citen, se rese&ntilde;en o se critiquen, o cuando el uso se realice a efectos de caricatura, parodia o pastiche.</p>
<p>Ocasionalmente, nosotros podremos invitarle a o proporcionarle los medios para que nos de su opini&oacute;n sobre la Plataforma, y en tales circunstancias, cualquier respuesta que nos pueda proporcionar se entender&aacute; como no confidencial y HearBound tendr&aacute; derecho, pero no obligaci&oacute;n, a usar tales opiniones sin restricciones.</p>
<h1>Concesi&oacute;n de licencia</h1>
<p>Mediante la subida o el posteo de Sus Contenidos en la Plataforma, usted inicia un proceso automatizado para transcodificar todo los Contenidos de audio e indica a HearBound que almacene Sus Contenidos en nuestros servidores, desde donde usted podr&aacute; controlar y autorizar su uso, la reproducci&oacute;n, transmisi&oacute;n, distribuci&oacute;n, exhibici&oacute;n p&uacute;blica, ejecuci&oacute;n p&uacute;blica y puesta a disposici&oacute;n al p&uacute;blico de Sus Contenidos en la Plataforma y la App. En la medida en resulte necesario para que HearBound le proporcione cualquiera de los servicios de HearBound aqu&iacute; mencionados, para que lleve a cabo cualquiera de las tareas expuestas en estos T&eacute;rminos y Condiciones de Uso, incluyendo la distribuci&oacute;n de publicidad u otro material promocional en nuestra Plataforma y/o para permitir el uso por su parte de la Plataforma, por la presente concedes tales licencias a HearBound de forma limitada, no exclusiva, para todo el mundo, libres de royalties y completamente pagadas.</p>
<p>Mediante la subida de Sus Contenidos a la Plataforma, usted tambi&eacute;n concede una licencia limitada, no exclusiva, para todo el mundo, libre de royalties y enteramente pagada, a favor de otros usuarios de la Plataforma, y de operadores y usuarios de cualquier otra p&aacute;gina web, aplicaci&oacute;n y/o plataformas con los que Sus Contenidos ha sido compartido o en los que se haya integrado utilizando los Servicios, a escuchar, repostear, transmitir o de otra forma distribuir, exhibir p&uacute;blicamente, ejecutar p&uacute;blicamente,&nbsp; poner a disposici&oacute;n del p&uacute;blico o comunicar p&uacute;blicamente de otro modo, Sus Contenidos ocasionalmente, usando las herramientas de la Plataforma y dentro de las par&aacute;metros fijados por usted al utilizar los Servicios. Usted podr&aacute; limitar y restringir la disponibilidad de algunos elementos de Sus Contenidos para otros usuarios de la Plataforma, y en cualquier momento, todo ello con sujeci&oacute;n a lo dispuesto m&aacute;s abajo en la secci&oacute;n Descargo de Responsabilidad. Sin perjuicio de lo anterior, nada de lo dispuesto en estos T&eacute;rminos y Condiciones de Uso confiere derecho alguno a ning&uacute;n otro usuario de la Plataforma con respecto a nombres protegidos, logos, marcas comerciales o de servicios que haya usted subido como parte de Sus Contenidos (por ejemplo, su imagen de perfil), m&aacute;s all&aacute; del derecho a reproducir, exhibir p&uacute;blicamente, poner a disposici&oacute;n del p&uacute;blico y comunicar al p&uacute;blico de otras formas tales Marcas, de forma autom&aacute;tica y sin alteraciones, como parte del acto de compartir pistas a las que usted haya asociado tales Marcas.</p>
<p>Las licencias otorgadas en esta secci&oacute;n se conceden de forma independiente para cada elemento de Sus Contenidos que usted suba a la Plataforma. Las licencias con respecto a Contenidos de audio, y cualesquiera im&aacute;genes o texto dentro de su cuenta, (con sujeci&oacute;n a lo dispuesto en el p&aacute;rrafo siguiente de estos T&eacute;rminos y Condiciones de Uso) se extinguir&aacute;n de forma autom&aacute;tica cuando usted retire tal Contenido de su cuenta.</p>
<p>La eliminaci&oacute;n de Contenidos de audio de su cuenta tendr&aacute; como resultado autom&aacute;tico la eliminaci&oacute;n de los archivos relevantes de los sistemas y servidores de HearBound.</p>
<p>Cualquier Contenido distinto de Sus Contenidos es propiedad del usuario correspondiente, y est&aacute; o puede estar protegido por derechos de autor o de marcas u otros derechos de propiedad intelectual o de otro tipo. Dicho Contenido no puede ser descargado, reproducido, distribuido, transmitido, re-subido, republicado, exhibido, vendido, licenciado, puesto a disposici&oacute;n al p&uacute;blico o comunicado p&uacute;blicamente de otro modo ni explotado con cualquier fin salvo ocasionalmente a trav&eacute;s de las herramientas de la Plataforma y dentro de los par&aacute;metros establecidos por el usuario en la Plataforma o con el consentimiento expreso y escrito del usuario. Con sujeci&oacute;n a los derechos expresamente otorgados en esta secci&oacute;n, todos los derechos sobre los Contenidos quedan reservados al usuario relevante.</p>
<h1>Manifestaciones y garant&iacute;as</h1>
<p>Por la presente usted declara y garantiza a HearBound lo siguiente:</p>
<ol>
<li>Sus Contenidos, y todas y cada una de sus partes, es o son una(s) obra(s) original(es) creadas por usted, o sobre las que ha obtenido todos los derechos, licencias, consentimientos y permisos necesarios para poder usarlas en cualquier momento mediante cualquiera de los usos aplicables, y (si y cuando fuera relevante) para autorizar a HearBound a usar Sus Contenidos de conformidad con los presentes T&eacute;rminos y Condiciones de Uso, incluyendo sin limitaci&oacute;n el derecho a subir, reproducir, almacenar, transmitir, distribuir, compartir, exhibir p&uacute;blicamente, ejecutar p&uacute;blicamente, poner a disposici&oacute;n&nbsp; y comunicar al p&uacute;blico de cualquier otro modo Sus Contenidos, y todas y cada una de sus partes, en, a trav&eacute;s de o mediante la Plataforma y todos y cada uno de los Servicios.</li>
<li>Sus Contenidos y su disponibilidad en la Plataforma no infringen o violan ni infringir&aacute;n o violar&aacute;n los derechos de ning&uacute;n tercero, incluyendo, sin limitaci&oacute;n, derechos de propiedad intelectual, derechos de artistas int&eacute;rpretes, derechos de imagen o de intimidad, o derechos sobre informaci&oacute;n confidencial.</li>
<li>Haber obtenido todos y cada uno de los consentimientos, permisos y/o descargos necesarios de todas y cada una de las personas que aparecen en Sus Contenidos para incluir sus nombres, voces, interpretaciones o apariencias en Sus Contenidos y para publicar los mismos en la Plataforma.</li>
<li>Sus Contenidos no es y no ser&aacute; ilegal, abusivo, calumnioso, difamatorio, pornogr&aacute;fico u obsceno, y no promover&aacute; ni incitar&aacute; a la violencia, al terrorismo, a acciones ilegales, o al odio por raz&oacute;n de raza, etnicidad, creencias religiosas, incapacidades, g&eacute;nero, identidad u orientaci&oacute;n sexual.</li>
<li>&nbsp;Sus Contenidos no generan ni generar&aacute;n responsabilidad alguna para HearBound, ni para sus subsidiarias, afiliadas, sucesores o cesionarios, ni para sus respectivos empleados, agentes, directores, oficiales y/o accionistas. HearBound se reserva el derecho a eliminar Sus Contenidos, suspender o cancelar tu acceso a la Plataforma y/o a perseguir todos los remedios legales si creemos que alguno de Sus Contenidos infringe alguna de las anteriores manifestaciones o garant&iacute;as, o de otro modo infringe los derechos de otra persona o viola alguna ley, norma o reglamento.</li>
</ol>
<h1>Responsabilidad por Contenidos</h1>
<p>Por la presente usted reconoce y acepta que HearBound:</p>
<ol>
<li>Almacena contenidos y otra informaci&oacute;n bajo la direcci&oacute;n, a petici&oacute;n de y con la autorizaci&oacute;n de sus usuarios</li>
<li>act&uacute;a como mero conducto pasivo y/o como anfitri&oacute;n para la subida, el almacenamiento y la distribuci&oacute;n de tal contenido, y</li>
<li>no juega ning&uacute;n papel activo y no presta asistencia en la presentaci&oacute;n o en el uso del contenido</li>
</ol>
<p>Usted es exclusivamente responsable de todos Sus Contenidos que usted suba, postee o distribuya a, en o a trav&eacute;s de la Plataforma, y en la medida en que est&eacute; permitido por las leyes, HearBound: se exime de toda responsabilidad con respecto a todo el contenido (incluidos Sus Contenidos) y de las actividades de sus usuarios con respecto a los mismos.</p>
<p>Por la presente usted reconoce y acepta que HearBound: no puede revisar y no revisa el contenido creado o subido por sus usuarios, y ni HearBound: ni sus subsidiarias, afiliadas, sucesores, cesionarios, empleados, agentes, directores, oficiales y accionistas tienen obligaci&oacute;n alguna, y pueden, aunque no se comprometen a ello ni asumen deber alguno, supervisar la plataforma por contenidos inapropiados, que infringen o podr&iacute;an infringir los derechos de alg&uacute;n tercero, o que de cualquier otra forma se han subido infringiendo estos T&eacute;rminos y Condiciones de Uso o la ley aplicable.</p>
<p>HearBound: y sus subsidiarias, afiliadas, sucesores, cesionarios, empleados, agentes, directores, oficiales y accionistas por la presente se eximen, en la m&aacute;xima medida permitida por la ley, de toda responsabilidad que pudiera derivarse de cualquier contenido subido a la Plataforma por los usuarios, incluyendo sin limitaci&oacute;n, cualquier reclamaci&oacute;n por infracci&oacute;n de derechos de propiedad intelectual, derechos de intimidad o de imagen, reclamaciones relativas a la publicaci&oacute;n de material abusivo, difamatorio, pornogr&aacute;fico u obsceno, o reclamaciones relativas a la exhaustividad, precisi&oacute;n, vigencia o fiabilidad de las informaciones proporcionadas por los usuarios de la Plataforma. Mediante el uso de la Plataforma renuncias de forma irrevocable a hacer valer una reclamaci&oacute;n con respecto a cualquiera de las cuestiones antes mencionadas contra HearBound o alguna de sus subsidiarias, afiliadas, sucesores, cesionarios, empleados, agentes, directores, oficiales o accionistas.</p>
<h1>Denuncia de infracciones</h1>
<p>HearBound respeta los derechos de propiedad intelectual y espera que sus usuarios hagan lo mismo. Si usted es titular de derechos de autor o copyright, o su agente, y considera que alguno de los materiales protegidos con copyright que est&aacute;n disponibles directamente a trav&eacute;s de HearBound vulnera sus derechos, por favor h&aacute;ganoslo saber a trav&eacute;s de las herramientas de la plataforma. Otra alternativa es enviar la notificaci&oacute;n al agente de copyright de Spotify a la siguiente direcci&oacute;n: contacto@hearbound.com</p>
<p>Debe dirigirse una notificaci&oacute;n de la supuesta infracci&oacute;n de derechos de autor o copyright al agente de copyright de HearBound como se indica arriba. Por favor incluya informaci&oacute;n lo m&aacute;s detallada posible para que podamos identificar los hechos y circunstancias, incluyendo, en la medida de lo posible:</p>
<ol>
<li>Una firma manuscrita o electr&oacute;nica del titular de los derechos de autor o copyright supuestamente infringidos (o de la persona autorizada para actuar en su nombre);</li>
<li>Identificaci&oacute;n espec&iacute;fica de cada obra cuyos derechos de autor o copyright hayan sido supuestamente infringidos;</li>
<li>Una descripci&oacute;n de d&oacute;nde se halla localizado en la Plataforma o App de HearBound y el material cuyos derechos de autor o copyright se consideran infringidos (por favor provea el m&aacute;ximo detalle posible y facilite una URL para ayudarnos a localizar el material que est&aacute; denunciando); tambi&eacute;n indiquenos el nombre de usuario y el de la canci&oacute;n.</li>
<li>Informaci&oacute;n de contacto de la parte reclamante, tal como el nombre completo, direcci&oacute;n, n&uacute;mero de tel&eacute;fono y direcci&oacute;n de correo electr&oacute;nico;</li>
<li>Una declaraci&oacute;n conforme la parte reclamante cree de buena fe que el uso del (de los) trabajo(s) en el modo por el que se reclama no est&aacute; autorizado por el titular de los derechos de copyright, su agente, o la ley; y</li>
<li>Una declaraci&oacute;n conforme la informaci&oacute;n proporcionada en la notificaci&oacute;n es precisa, y la parte reclamante es la titular del derecho supuestamente infringido, o su agente.</li>
</ol>
<p>Tambi&eacute;n consideramos oportuno hacerle saber que HearBound tiene una pol&iacute;tica para cancelar, en las circunstancias apropiadas, las cuentas de aquellos abonados que sean infractores recurrentes.</p>
<p>Este proceso se aplica solo para propiedad intelectual. Si usted descubre alg&uacute;n Contenido que crea que vulnera sus derechos de marca, por favor notif&iacute;quenoslo por correo electr&oacute;nico a contacto@hearbound.com. Para todos los dem&aacute;s casos, si descubriera Contenidos que infringen o violan cualquier otro de sus derechos, que consideres difamatorio, pornogr&aacute;fico, obsceno, racista o de cualquier otro modo capaz de causar da&ntilde;os generalizados, o que constituye suplantaci&oacute;n de personalidad, abuso, spam o de cualquier otro modo infringe estos T&eacute;rminos y Condiciones de Uso o la ley aplicable, por favor inf&oacute;rmenos en contacto@hearbound.com</p>
<h1>P&aacute;ginas web y servicios de terceros</h1>
<p>La P&aacute;gina Web y la Plataforma puede proporcionarle acceso a p&aacute;ginas web de terceros, bases de datos, redes, servidores, informaci&oacute;n, software, programas, sistemas, directorios, aplicaciones, productos o servicios, incluyendo sin limitaci&oacute;n servicios enlazados (en adelante, &ldquo;Servicios Externos&rdquo;).</p>
<p>HearBound no tiene ni mantiene control alguno sobre los Servicios Externos, y no es ni puede ser responsable por su contenido, operaci&oacute;n o uso. Mediante su enlace o de otro modo proporcionando acceso a alg&uacute;n Servicio Externo, HearBound no manifiesta, garantiza o avala, expl&iacute;cita o impl&iacute;citamente, la legalidad, precisi&oacute;n, calidad o autenticidad del contenido, la informaci&oacute;n o los servicios proporcionados por tales Servicios Externos.</p>
<p>Los Servicios Externos pueden tener sus propios t&eacute;rminos y condiciones de uso y/o pol&iacute;tica de privacidad, y pueden tener pr&aacute;cticas y requisitos distintos a aquellos con los que opera HearBound con relaci&oacute;n a la Web y la Plataforma. Usted es exclusivamente responsable de revisar los t&eacute;rminos y condiciones de uso, pol&iacute;tica de privacidad u otros t&eacute;rminos que puedan regular el uso por su parte de estos Servicios Externos, que usar&aacute; bajo tu propio riesgo. Se recomienda que haga averiguaciones e investigaciones razonables antes de cerrar ninguna transacci&oacute;n, financiera o de otro tipo, ya sea online u offline, con terceros relacionados con cualquier Servicio Externo.</p>
<h1>Servicios Externos</h1>
<p>Usted es exclusivamente responsable de adoptar las precauciones necesarias para protegerse de fraudes al usar Servicios Externos, y de proteger sus sistemas inform&aacute;ticos de virus, gusanos, troyanos, y otro contenido da&ntilde;ino o destructivo y de material que pueda estar incluido en o emanar de alguno de los Servicios Externos.</p>
<p>HearBound se descarga de cualquier responsabilidad por da&ntilde;os resultantes del uso por su parte de los Servicios Externos, y por la presente usted renuncia de forma irrevocable a reclamar a HearBound con respecto al contenido o a las operaciones de ninguno de los Servicios Externos.</p>
<h1>Bloqueo y Retirada de contenido</h1>
<p>Sin perjuicio de que HearBound no tenga obligaci&oacute;n de supervisar el contenido en la Plataforma, HearBound se reserva el derecho a bloquear, retirar o borrar cualquier contenido en cualquier momento, y a limitar o restringir el acceso a cualquier contenido, por cualquier raz&oacute;n y sin responsabilidad alguna, incluyendo sin limitaci&oacute;n, en caso de que tengamos razones para creer que tal contenido infringe o puede infringir los derechos de terceros, ha sido subido o posteado en vulneraci&oacute;n de estos T&eacute;rminos y Condiciones de Uso o la ley aplicable, o es de otro modo inaceptable para HearBound.</p>
<p>Por favor, tenga tambi&eacute;n en cuenta que los usuarios individuales tienen control sobre el contenido de audio que almacenan en su cuenta de tanto en tanto, y pueden quitar una parte o todo el contenido de audio u otro contenido sin previo aviso. Usted no tendr&aacute; derecho a seguir accediendo a un elemento particular de contenido y HearBound no tendr&aacute; responsabilidad alguna en el caso de que no le sea posible acceder a un elemento de contenido debido a su eliminaci&oacute;n de la Plataforma, ya haya sido por HearBound o por el usuario relevante.</p>
<h1>Infractores Reincidentes</h1>
<p>HearBound suspender&aacute; o cancelar&aacute; su acceso a la Plataforma si HearBound determina, a su razonable criterio, que usted ha incumplido repetidamente estos T&eacute;rminos y Condiciones de Uso.</p>
<p>Si recibimos una notificaci&oacute;n v&aacute;lida de un tercero de conformidad con nuestros procesos de denuncia o la ley aplicable de que alguno de Sus Contenidos infringe la propiedad intelectual u otros derechos del mencionado tercero, o si creemos que su conducta vulnera nuestros T&eacute;rminos y Condiciones de uso, le enviaremos un aviso por escrito a estos efectos. Cualquier usuario que reciba m&aacute;s de dos de estos avisos es susceptible de que su acceso a la Plataforma sea cancelado en el acto.</p>
<p>Tambi&eacute;n suspenderemos o cancelaremos su cuenta sin previo aviso si as&iacute; nos lo ordena un juez, y/o en otras circunstancias apropiadas, tal como pueda determinar HearBound a su solo criterio. Por favor tenga en cuenta que no ofrecemos reembolsos a titulares de cuentas de Suscripci&oacute;n cuyas cuentas hayan sido canceladas como consecuencia de infracciones reiteradas o cualquier violaci&oacute;n de estos T&eacute;rminos y Condiciones de Uso.</p>
<h1>Descargo de Responsabilidad</h1>
<p>LA PLATAFORMA, INCLUYENDO, SIN LIMITACI&Oacute;N, LA P&Aacute;GINA WEB, LAS APPS Y TODO EL CONTENIDO Y LOS SERVICIOS A LOS QUE SE ACCEDE A TRAV&Eacute;S O MEDIANTE LA P&Aacute;GINA WEB, LAS APPS, LOS SERVICIOS O DE CUALQUIER OTRO MODO, SE OFRECEN &ldquo;TAL CUAL EST&Aacute;N&rdquo;, &ldquo;SEG&Uacute;N DISPONIBILIDAD&rdquo; Y &ldquo;CON TODAS LAS FALLAS QUE PUEDAN TENER&rdquo;.</p>
<p>A PESAR DE QUE HEARBOUND HACE ESFUERZOS RAZONABLES PARA CORREGIR POSIBLES ERRORES U OMISIONES EN LA PLATAFORMA TAN PRONTO COMO LE ES POSIBLE UNA VEZ &Eacute;STAS HAN SIDO PUESTAS EN CONOCIMIENTO DE HEARBOUND, HEARBOUND NO HACE NINGUNA PROMESA, GARANT&Iacute;A O MANIFESTACI&Oacute;N DE NING&Uacute;N TIPO (EXPRESA O IMPL&Iacute;CITA) EN RELACI&Oacute;N CON LA P&Aacute;GINA WEB, LAS APLICACIONES, LOS SERVICIOS O UNA PARTE O PARTES DE LOS MISMOS, NI EN RELACI&Oacute;N CON NING&Uacute;N CONTENIDO. HEARBOUND NO GARANTIZA QUE EL USO POR SU PARTE DE LA PLATAFORMA NO SE INTERRUMPIR&Aacute;, SER&Aacute; PUNTUAL, SEGURO O ESTAR&Aacute; LIBRE DE ERRORES, QUE LOS DEFECTOS SER&Aacute;N CORREGIDOS O QUE LA PLATAFORMA O UNA O ALGUNA DE SUS PARTES, EL CONTENIDO O LOS SERVIDORES EN LOS CUALES OPERA LA PLATAFORMA EST&Aacute;N O ESTAR&Aacute;N LIBRES DE VIRUS O DE CUALQUIER OTRO COMPONENTE DA&Ntilde;INO. HEARBOUND NO GARANTIZA QUE TODAS LAS TRANSMISIONES DE CONTENIDO SUBIDO A LA PLATAFORMA SER&Aacute;N SEGURAS O QUE TODOS LOS ELEMENTOS DE LA PLATAFORMA DISE&Ntilde;ADOS PARA PREVENIR ACCESOS, ACTOS DE COMPARTIR O BAJADAS DE CONTENIDO NO AUTORIZADOS, SER&Aacute;N EFICACES EN TODOS Y CADA UNO DE LOS CASOS, Y TAMPOCO GARANTIZA QUE EL USO QUE USTED HAGA DE LA PLATAFORMA SER&Aacute; LEGAL EN NINGUNA JURISDICCI&Oacute;N EN PARTICULAR.</p>
<p>EN LA M&Aacute;XIMA MEDIDA PERMITIDA POR LA LEY, HEARBOUND Y SUS SUBSIDIARIAS, AFILIADAS, SUCESORES, Y CESIONARIOS, Y SUS RESPECTIVOS EMPLEADOS, AGENTES, DIRECTORES, OFICIALES Y ACCIONISTAS, ESPEC&Iacute;FICAMENTE DECLINAN OFRECER NINGUNA DE LAS ANTERIORES GARANT&Iacute;AS Y CUALESQUIERA OTRAS QUE NO EST&Eacute;N ESPEC&Iacute;FICAMENTE MENCIONADAS AQU&Iacute;, INCLUYENDO SIN LIMITACI&Oacute;N CUALESQUIERA GARANT&Iacute;AS EXPRESAS O IMPL&Iacute;CITAS RELATIVAS A LA NO-INFRACCI&Oacute;N, COMERCIABILIDAD Y ADECUACI&Oacute;N A UN FIN PARTICULAR.</p>
<p>CUANDO LA LEY DE ALGUNA JURISDICCI&Oacute;N LIMITE O PROH&Iacute;BA EL ANTERIOR DESCARGO DE RESPONSABILIDAD DE GARANT&Iacute;AS IMPL&Iacute;CITAS O DE OTRO TIPO TAL COMO SE DESCRIBEN M&Aacute;S ARRIBA, LOS DESCARGOS DE RESPONSABILIDAD ARRIBA DESCRITOS NO SER&Aacute;N DE APLICACI&Oacute;N EN LA MEDIDA EN QUE LA LEY DE DICHA JURISDICCI&Oacute;N SEA DE APLICACI&Oacute;N A ESTE CONTRATO.</p>
<h1>Limitaci&oacute;n de responsabilidad</h1>
<p>EN NING&Uacute;N CASO LA RESPONSABILIDAD AGREGADA DE HEARBOUND HACIA USTED BAJO ESTE CONTRATO EXCEDER&Aacute; LO QUE RESULTE MAYOR ENTRE 100 EUROS O LAS CANTIDADES (SI EXISTEN) PAGADAS POR TI A HEARBOUND DURANTE LOS DOCE (12) MESES ANTERIORES POR LOS SERVICIOS QUE DAN LUGAR A LA RECLAMACI&Oacute;N.</p>
<p>HEARBOUND Y SUS SUBSIDIARIAS, AFILIADAS, SUCESORES, Y CESIONARIOS, Y SUS RESPECTIVOS EMPLEADOS, AGENTES, DIRECTORES, OFICIALES Y ACCIONISTAS, NO TENDR&Aacute;N RESPONSABILIDAD ALGUNA POR:</p>
<ol>
<li>P&Eacute;RDIDAS O DA&Ntilde;OS DERIVADOS DE:</li>
<li>CUALQUIER P&Eacute;RDIDA O DA&Ntilde;OS PRODUCIDOS A SU EQUIPO O SUS PROGRAMAS INFORM&Aacute;TICOS, CUALQUIER P&Eacute;RDIDA DE DATOS (INCLUYENDO SUS CONTENIDOS), O CUALUQIER P&Eacute;RDIDA O DA&Ntilde;OS DERIVADOS DE UNA QUIEBRA EN LA SEGURIDAD; Y/O</li>
<li>CUALESQUIERA P&Eacute;RDIDAS DE BENEFICIOS, O CUALESQUIERA OTRAS P&Eacute;RDIDAS O DA&Ntilde;OS QUE PUEDAS SUFRIR QUE NO SEAN CONSECUENCIA PREVISIBLE DEL INCUMPLIMIENTO POR PARTE DE HEARBOUND DE ESTOS T&Eacute;RMINOS Y CONDICIONES DE USO. LAS P&Eacute;RDIDAS SON PREVISIBLES CUANDO PUEDAN SER TENIDOS EN CUENTA POR USTED Y POR HEARBOUND EN EL MOMENTO DE SU ACEPTACI&Oacute;N DE ESTOS T&Eacute;RMINOS Y CONDICIONES DE USO, Y POR LO TANTO NO INCLUIR&Aacute;N DA&Ntilde;OS O P&Eacute;RDIDAS INDIRECTAS DE NINGUNA CLASE, COMO POR EJEMPLO LA P&Eacute;RDIDA DE OPORTUNIDADES.</li>
</ol><ol start="2">
<li>(A) LA IMPOSIBILIDAD DE ACCEDER O USAR LAS APPS O ALGUNA DE SUS PARTES, O DE ACCEDER A ALG&Uacute;N CONTENIDO; (B) CUALESQUIERA CAMBIOS QUE HEARBOUND PUEDA EFECTUAR EN LA PLATAFORMA O EN ALGUNA DE SUS PARTES, O DE CUALQUIER SUSPENSI&Oacute;N O CESE TEMPORAL O PERMANENTE DEL ACCESO A LA PLATAFORMA O A ALG&Uacute;N CONTENIDO EN O DESDE UNO O TODOS LOS TERRITORIOS; (C) ALGUNA ACCI&Oacute;N INICIADA CONTRA USTED POR TERCEROS TITULARES DE DERECHOS CON RESPECTO A ALGUNA PRESUNTA INFRACCI&Oacute;N DE LOS DERECHOS DE DICHO TERCERO RELACIONADA CON SUS CONTENIDOS O CON EL USO POR SU PARTE DE LA PLATAFORMA, O ALGUNA ACCI&Oacute;N REALIZADA COMO PARTE DE UNA INVESTIGACI&Oacute;N POR HEARBOUND O POR LAS AUTORIDADES RELEVANTES ENCARGADAS DEL CUMPLIMIENTO DE LA LEY EN RELACI&Oacute;N CON EL USO QUE USTED HAGA DE LA PLATAFORMA; (D) CUALESQUIERA ERRORES U OMISIONES EN LA OPERATIVA T&Eacute;CNICA DE LA PLATAFORMA, O DE CUALQUIER INEXACTITUD O DEFECTO EN CUALQUIER CONTENIDO O INFORMACI&Oacute;N RELATIVA A CONTENIDO; (E) SU INCAPACIDAD PARA PROPORCIONAR A HEARBOUND INFORMACI&Oacute;N PRECISA O COMPLETA, O SU INCAPACIDAD PARA MANTENER SU NOMBRE DE USUARIO O SU CONTRASE&Ntilde;A DEBIDAMENTE CONFIDENCIAL; (F) CUALQUIER CONDUCTA INDEBIDA POR PARTE DE OTROS USUARIOS O DE TERCEROS AL USAR LA PLATAFORMA, ESPECIALMENTE CUANDO CON ELLO SE INCUMPLA ESTE CONTRATO;</li>
</ol>
<p>CUALQUIER RECLAMACI&Oacute;N O ACCI&Oacute;N DERIVADA DE O RELATIVA AL USO POR SU PARTE DE LA PLATAFORMA DEBER&Aacute; SER NOTIFICADA A HEARBOUND TAN PRONTO COMO SEA POSIBLE.</p>
<p>ES POSIBLE QUE LA LEY APLICABLE NO PERMITA LA LIMITACI&Oacute;N O EXENCI&Oacute;N DE RESPONSABILIDAD POR DA&Ntilde;OS INCIDENTALES O EMERGENTES, DE MANERA QUE PUEDE QUE LAS ANTERIORES LIMITACIONES O EXENCIONES NO LE RESULTEN DE APLICACI&Oacute;N. EN TALES CASOS, USTED RECONOCE Y ACEPTA QUE TALES LIMITACIONES Y EXENCIONES SON REFLEJO DE UNA ASIGNACI&Oacute;N DE RIESGOS ENTRE HEARBOUND Y USTED JUSTA Y RAZONABLE, LO QUE CONSTITUYE UN ELEMENTO FUNDAMENTAL DE LA NEGOCIACI&Oacute;N ENTRE HEARBOUND Y USTED, Y QUE LA RESPONSABILIDAD DE HEARBOUND EST&Aacute; LIMITADA EN LA MAXIMA MEDIDA PERMITIDA POR LA LEY.</p>
<p>NADA DE LO ESTABLECIDO EN ESTOS T&Eacute;RMINOS Y CONDICIONES DE USO LIMITA O EXCLUYE LA RESPONSABILIDAD DE HEARBOUND, SUS SUBSIDIARIAS, AFILIADAS, SUCESORES, Y CESIONARIOS, Y SUS RESPECTIVOS EMPLEADOS, AGENTES, DIRECTORES, OFICIALES Y ACCIONISTAS:</p>
<ol>
<li>POR LA MUERTE O DA&Ntilde;OS PERSONALES CAUSADOS POR LA NEGLIGENCIA DE UNOS O DE OTROS</li>
<li>POR CUALQUIER FORMA DE FRAUDE O ENGA&Ntilde;O</li>
<li>POR CUALESQUIERA DA&Ntilde;OS CAUSADOS DE FORMA DOLOSA O CON NEGLIGENCIA GRAVE, O</li>
<li>POR CUALQUIER FORMA DE RESPONSABILIDAD QUE POR LEY NO PUEDA SER LIMITADO O EXCLUIDO.</li>
</ol>
<h1>Indemnizaci&oacute;n</h1>
<p>Por la presente usted acepta indemnizar, defender y mantener indemne a HearBound, sus sucesores, cesionarios, afiliados, agentes, directores, oficiales, empleados y accionistas de y contra cualesquiera reclamaciones, obligaciones, da&ntilde;os, p&eacute;rdidas, gastos y costes, incluyendo honorarios razonables de abogados, derivados de:</p>
<ol>
<li>Cualquier violaci&oacute;n por su parte de estos T&eacute;rminos y Condiciones de Uso;</li>
<li>Cualquier reclamaci&oacute;n de terceros por la infracci&oacute;n de derechos de propiedad intelectual, o por la invasi&oacute;n de privacidad derivada del almacenamiento de Sus Contenidos en la Plataforma, y/o la puesta a disposici&oacute;n por su parte de los mismos de otros usuarios de la Plataforma y la App, y/o el uso efectivo de Sus Contenidos por otros usuarios de Plataforma y la App de acuerdo con estos T&eacute;rminos y Condiciones de Uso y los par&aacute;metros por usted establecidos con respecto a la distribuci&oacute;n de Sus Contenidos y el acto de compartir los mismos;</li>
<li>Cualquier actividad relacionada con tu cuenta, ya sea llevada a cabo por ti o por otra persona que acceda a su cuenta con o sin su consentimiento, salvo que dicha actividad haya sido causada por acci&oacute;n u omisi&oacute;n de HearBound</li>
</ol>
<h1>Protecci&oacute;n de datos, Privacidad y Cookies</h1>
<p>Todos los datos personales que nos proporcione en conexi&oacute;n con el uso por su parte de la Web, la Plataforma y la App ser&aacute;n recopilados, almacenados, revelados y de cualquier otra manera tratados por HearBound de acuerdo con nuestra Pol&iacute;tica de Privacidad. Adem&aacute;s, tal como hacen la mayor&iacute;a de servicios en l&iacute;nea, usamos cookies para mejor entender c&oacute;mo utiliza la gente la Plataforma, de manera que podamos continuar mejorando el servicio que ofrecemos. Nuestro uso de cookies, y el modo de desactivarlas, se explica en nuestra Pol&iacute;tica de Cookies.</p>
<h1>Cambios en la Plataforma y las cuentas</h1>
<p>HearBound se reserva el derecho a suspender, interrumpir, finalizar o cesar en el acceso a las Apps o a alguna parte de la misma, en cualquier momento y por cualquier motivo, de forma temporal o permanente, y ya sea en su totalidad o solo con respecto a algunos territorios individuales. En el caso de suspensi&oacute;n permanente o temporal, interrupci&oacute;n, finalizaci&oacute;n o cesaci&oacute;n del acceso, HearBound har&aacute; sus mejores esfuerzos para notificar previamente tal decisi&oacute;n a los usuarios registrados.</p>
<p>Usted acepta que HearBound y sus subsidiarias, afiliadas, cesionarias, empleados, agentes, directores, oficiales y accionistas no ser&aacute;n responsables ante usted o ante cualquier tercero por cualesquiera cambios o modificaciones a la P&aacute;gina Web, las Apps y/o cualesquiera de los Servicios que HearBound pueda decidir realizar ocasionalmente, o por cualquier decisi&oacute;n de suspender, interrumpir o cerrar la P&aacute;gina Web, los Servicios o parte o partes de alguno de ellos, o su posibilidad de usar o acceder a los mismos desde alg&uacute;n territorio o territorios o dentro de ellos.</p>
<p>HearBound podr&aacute; cambiar las funcionalidades de cualquier tipo de cuenta, podr&aacute; sacar o introducir nuevas funcionalidades, productos o tipos de cuenta en cualquier momento y por cualquier motivo. Si dichos cambios fueran materiales y representaran una desventaja para usted, HearBound le notificar&aacute; los cambios propuestos mediante un correo electr&oacute;nico enviado a su cuenta de HearBound&nbsp; y/o un correo electr&oacute;nico a la cuenta de correo electr&oacute;nico en tal momento vigente de la que dispongamos para su cuenta, con al menos seis (6) semanas de antelaci&oacute;n. Usted no tendr&aacute; obligaci&oacute;n de continuar usando la Plataforma a partir de la recepci&oacute;n de dicha notificaci&oacute;n, pero si no cierra su cuenta tal como se describe m&aacute;s abajo en la secci&oacute;n Finalizaci&oacute;n durante el mencionado per&iacute;odo de seis (6) semanas, el uso continuado de la cuenta despu&eacute;s de finalizar ese per&iacute;odo de seis (6) semanas constituir&aacute; su aceptaci&oacute;n de los cambios.</p>
<h1>Finalizaci&oacute;n</h1>
<p>Usted puede terminar este Contrato en cualquier momento mediante el borrado de su cuenta y dejando de usar la Plataforma a partir de ese momento. Si usted tiene una Suscripci&oacute;n, y resuelve este Contrato antes de que dicha Suscripci&oacute;n finalice, no podemos reembolsarle por el per&iacute;odo de su Suscripci&oacute;n que a&uacute;n no hubiera expirado.</p>
<p>HearBound puede suspender su acceso a las Apps y/o resolver este Contrato en cualquier momento si</p>
<ol>
<li>usted es considerado un [Infractor Reincidente] tal como se describe m&aacute;s arriba;</li>
<li>usted ha incumplido cualquiera de los t&eacute;rminos materiales de estos T&eacute;rminos y Condiciones de Uso, incluyendo sin limitaci&oacute;n, los t&eacute;rminos y condiciones contenidos en las siguientes secciones: &ldquo;Su Uso de la Plataforma&rdquo;, &ldquo;Sus Contenidos&rdquo;, &ldquo;Concesi&oacute;n de Licencia&rdquo;, y &ldquo;Sus Manifestaciones y Garant&iacute;as&rdquo;;</li>
<li>HearBound decide a su solo criterio dejar de proporcionar acceso a la Plataforma en la jurisdicci&oacute;n en la que usted reside o desde la que usted est&aacute; intentando acceder a la Plataforma, o</li>
<li>en otras circunstancias razonables a determinar por HearBound bajo su exclusivo criterio.</li>
</ol>
<p>Una vez que su cuenta haya sido cancelada, todos y cada uno de los Contenidos alojados en su cuenta, o relacionados con actividades de su cuenta (por ejemplo, datos relativos a la distribuci&oacute;n o el consumo de sus pistas), ser&aacute;n eliminados de forma permanente por HearBound, salvo en la medida en que estemos obligados o nos est&eacute; permitido retener tal contenido, datos o informaci&oacute;n por un per&iacute;odo de tiempo determinado de acuerdo con las leyes y reglamentos aplicables y/o con el fin de proteger nuestros intereses comerciales leg&iacute;timos. Le recomendamos que guarde o haga copias de seguridad de todo el material que haya subido a su cuenta antes de cancelarla, ya que HearBound no asume responsabilidad alguna por el material que ser&aacute; permanentemente eliminado tras la cancelaci&oacute;n de su cuenta. HearBound no tiene la posibilidad de proporcionarle archivos de datos .csv o similares relativos a la actividad asociada a su cuenta despu&eacute;s de la resoluci&oacute;n o cancelaci&oacute;n. Estos datos se proporcionan y son accesibles solo para su visionado a trav&eacute;s de la Plataforma durante el tiempo en que su cuenta est&eacute; activa.</p>
<p>Si usted accede a las Apps, la eliminaci&oacute;n de dicha app no conllevar&aacute; la eliminaci&oacute;n de su cuenta. Si usted desea borrar su cuenta, necesitar&aacute; hacerlo desde la p&aacute;gina de su Cuenta dentro de sus Ajustes en la plataforma. Las disposiciones de estos T&eacute;rminos y Condiciones de Uso que est&aacute;n destinadas por su propia naturaleza a perdurar en el tiempo m&aacute;s all&aacute; de la finalizaci&oacute;n o cancelaci&oacute;n de este Contrato perdurar&aacute;n en el tiempo despu&eacute;s de que &eacute;ste haya finalizado, incluyendo, sin limitaci&oacute;n, aquellas Secciones tituladas Su Cuenta HearBound, Sus Contenidos, Concesi&oacute;n de Licencia, Manifestaciones y Garant&iacute;as, Responsabilidad por Contenidos, Descargo de Responsabilidad, Limitaci&oacute;n de Responsabilidad, Indemnizaci&oacute;n, Finalizaci&oacute;n, Cesi&oacute;n a Terceros, Divisibilidad, Contrato Completo, y Ley Aplicable y Jurisdicci&oacute;n, respectivamente.</p>
<h1>Cesi&oacute;n a Terceros</h1>
<p>HearBound podr&aacute; transmitir sus derechos y (cuando est&eacute; permitido por la ley) sus obligaciones contenidas en este Contrato, en todo o en parte, a cualquier tercero en cualquier momento sin necesidad de notificarlo, incluyendo sin limitaci&oacute;n, a cualquier persona o entidad que adquiera todos o una parte sustancial de los activos o del negocio de HearBound. Usted no podr&aacute; transmitir este Contrato o los derechos y deberes en &eacute;l contenidos, en todo o en parte, a ning&uacute;n tercero sin el consentimiento previo y escrito de HearBound.</p>
<h1>Divisibilidad</h1>
<p>En caso de que se entendiera que una o m&aacute;s disposiciones de estos T&eacute;rminos y Condiciones de Uso no se ajustan a la ley, son nulas o inaplicables, dicha(s) disposici&oacute;n(es) se considerar&aacute;n separables del Contrato y no afectar&aacute;n a la validez o a la eficacia del resto de las disposiciones de dichos T&eacute;rminos y Condiciones de Uso, que permanecer&aacute;n en vigor y surtir&aacute;n plenos efectos.</p>
<h1>Contrato completo</h1>
<p>Estos T&eacute;rminos y Condiciones de Uso constituyen el acuerdo completo entre usted y HearBound con relaci&oacute;n al uso de la Web y la Plataforma y sustituyen a cualquier acuerdo previo entre usted y HearBound. Cualquier modificaci&oacute;n del presente Contrato deber&aacute; realizarse por escrito.</p>
<h1>Derechos de Terceros</h1>
<p>Estos T&eacute;rminos y Condiciones de Uso no est&aacute;n destinados a otorgar derechos a nadie excepto a usted y a HearBound. Ello no afecta a nuestro derecho a transmitir nuestros derechos u obligaciones a terceros tal como se describe en la secci&oacute;n Cesi&oacute;n a Terceros.</p>
<h1>Ley Aplicable y Jurisdicci&oacute;n</h1>
<p>Salvo en aquellos casos en que normas imperativas en los Estados Unidos o en cualquier estado miembro de la Uni&oacute;n Europea exijan algo distinto</p>
<ol>
<li>Este Contrato est&aacute; sometido a las leyes espa&ntilde;olas, con exclusi&oacute;n del Convenio de Naciones Unidas sobre Contratos para la Venta Internacional de Mercanc&iacute;as (CISG en sus siglas en ingl&eacute;s) y excluyendo las normas de conflicto aplicables (de derecho internacional privado); y</li>
<li>Usted acepta, y HearBound acepta, someterse a la jurisdicci&oacute;n exclusive de los juzgados y tribunales de la ciudad de Madrid, Espa&ntilde;a, para la resoluci&oacute;n de cualquier disputa, acci&oacute;n o procedimiento que pudiera tener lugar en conexi&oacute;n con este Contrato.</li>
</ol>
<p>Las disposiciones precedentes de esta secci&oacute;n sobre Ley Aplicable y Jurisdicci&oacute;n no ser&aacute;n aplicables a aquellas reclamaciones en las que HearBound persiga una compensaci&oacute;n o remedio equitativo de cualquier tipo distinto al monetario. Usted reconoce que, en caso de incumplimiento de este contrato por parte de HearBound o de cualquier tercero, el da&ntilde;o o perjuicio, si existiera, que se le hubiera causado no le dar&aacute; derecho a reclamar compensaci&oacute;n equitativa de ning&uacute;n tipo m&aacute;s all&aacute; de una indemnizaci&oacute;n monetaria por da&ntilde;os contra HearBound, incluso en relaci&oacute;n con Sus Contenidos, y el &uacute;nico remedio que podr&aacute; reclamar ser&aacute; una indemnizaci&oacute;n monetaria, todo ello con sujeci&oacute;n a las limitaciones de responsabilidad previstas en estos T&eacute;rminos y Condiciones de Uso.</p>
<h1>Informaci&oacute;n</h1>
<p>Estos servicios son ofrecidos por HearBound S.L., una compa&ntilde;&iacute;a en proceso de constituci&oacute;n de acuerdo con las leyes de Espa&ntilde;a y con su establecimiento principal de negocio en Calle de Villablanca, 85, 28032 Madrid, Espa&ntilde;a. Puede ponerse en contacto con nosotros mediante correo electr&oacute;nico enviado a contacto@hearbound.com</p>
    </div>
    <Footer />
    </div>
  );
};

export default Conditions;
