import { useState, useEffect } from "react";
import Navbar from "../../../Components/NavBar/NavBar";
import Footer from "../../../Components/Footer/Footer";
import Control from "../../../Images/Control.jpg";
import Osito from "../../../Images/osito.png";
import MusicaUnica from "../../../Images/TusReglas.svg";
import "../Usuarios/Usuarios.css";
import "./Artistas.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Artistas = () => {
  AOS.init();
  const [offset, setOffset] = useState(0);
  let o = "translateX(" + -offset / 2 + "px)";

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <>
      <div className="mainUsers">
        <Navbar />
        <div className="firstArtists" style={{ backgroundColor: "#010F52" }}>
          <div
            className="firstLeftUsers"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h1 className="firstArtistsTitle" style={{ color: "#ff5bef" }}>
              EL CAMBIO NUNCA ESTUVO TAN CERCA
            </h1>
            {/* <h2 className="firstUsersSubtitle">
              Nunca ha sido tan fácil descubrir música...
            </h2> */}
          </div>

          <div
            className="firstRightUsers"
            data-aos="fade-right"
            data-aos-delay="600"
          >
            <img className="firstUsersImage" src={MusicaUnica} alt='' />
          </div>
        </div>
        <div className="secondUsers">
          <div
            className="secondLeftContainerArtists"
            style={{ backgroundColor: "#010F52" }}
          >
            <div
              className="secondLeftArtists"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h1 className="secondArtistsTitleLeft">
                Usar HearBound es sencillo
              </h1>
            </div>
          </div>
          <div
            className="secondRightContainerUsers"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="secondRightUsers"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="secondUsersTitle" style={{ color: "#bd00d9" }}>
                Pensado para que no tengas que pensar
              </h2>
              <p className="artistText">
                HearBound está pensado para que aproveches lo que ya tienes,
                aquí no tienes que crear un anuncio impresionante para llamar la
                atención de tu público.
              </p>
              <p className="artistText">
                Sube tu videoclip, elige la portada de tu tema, configura tus
                géneros y disfruta de un lugar pensado para que te descubran.
              </p>
              <p className="artistText">Sí, así de simple.</p>
            </div>
          </div>
        </div>
        <section
          className="second slideTextUsers"
          style={{
            backgroundColor: "#00A0C0",
          }}
        >
          <div className="overflow">
            <p style={{ transform: o, scrollBehavior: "smooth" }}>
              <span
                className="slideText slideTextUsers"
                style={{
                  opacity: "1",
                  WebkitTextStrokeColor: "#FFF",
                }}
              >
                CREADO POR ARTISTAS, PARA ARTISTAS. CREADO POR ARTISTAS, PARA
                ARTISTAS. CREADO POR ARTISTAS, PARA ARTISTAS. CREADO POR
                ARTISTAS, PARA ARTISTAS. CREADO POR ARTISTAS, PARA ARTISTAS.
                CREADO POR ARTISTAS, PARA ARTISTAS. CREADO POR ARTISTAS, PARA
                ARTISTAS.
              </span>
            </p>
          </div>
        </section>
        <div className="thirdUsers">
          <div
            className="secondLeftContainerUsers"
            style={{ backgroundColor: "#00A0C0" }}
          >
            <div
              className="thirdLeftArtists"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="secondUsersTitle" style={{ color: "#010F52" }}>
                Ten el control en todo momento.
              </h2>
              <p className="artistText" style={{ color: "#F9F9F9" }}>
                Con HearBound se acabó no saber que está pasando con tu música
                en todo momento. Tus estadísticas estarán al alcance de un clic.
              </p>
              <p className="artistText" style={{ color: "#F9F9F9" }}>
                Tendrás datos precisos: cuántas personas te están escuchando en
                tiempo real, el rango de edad de tus oyentes, el tiempo de
                escucha, los países donde más destacas y mucho más, para que
                sepas en todo momento qué está ocurriendo con tu música.
              </p>
            </div>
          </div>
          <div
            className="secondRightContainerUsers"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="thirdRightUsers"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <img src={Control} className="secondLeftUsers" alt=''/>
            </div>
          </div>
        </div>
        <section
          className="second slideTextUsers"
          style={{
            backgroundColor: "#00A0C0",
          }}
        >
          <div className="overflow">
            <p style={{ transform: o, scrollBehavior: "smooth" }}>
              <span
                className="slideText slideTextUsers"
                style={{
                  opacity: "1",
                  WebkitTextStrokeColor: "#FFF",
                }}
              >
                TÚ EN HEARBOUND, MANDAS TÚ EN HEARBOUND, MANDAS TÚ EN HEARBOUND,
                MANDAS TÚ EN HEARBOUND, MANDAS TÚ EN HEARBOUND, MANDAS TÚ EN
                HEARBOUND, MANDAS TÚ EN HEARBOUND, MANDAS TÚ EN HEARBOUND,
                MANDAS TÚ EN HEARBOUND,
              </span>
            </p>
          </div>
        </section>
        <div className="secondUsers">
          <div
            className="secondLeftContainerArtists"
            style={{ backgroundColor: "#00A0C0" }}
          >
            <div
              className="secondLeftArtists"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <img src={Osito} className="ositoImg" alt=''/>
            </div>
          </div>
          <div
            className="secondRightContainerUsers"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="secondRightUsers"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="secondUsersTitle" style={{ color: "#f303db" }}>
                Crece, crece{" "}
                <span className="spanTitleUsers" style={{ color: "#bd00d9" }}>
                  y crece...
                </span>
              </h2>
              <p className="artistText" style={{ color: "#000" }}>
                HearBound está pensado solo para descubrir música. Una vez seas
                descubierto, podrás redirigir a tu publico allí donde quieras...
              </p>

              <p className="artistText" style={{ color: "#000" }}>
                Tus oyentes guardarán tus canciones en las plataformas de
                streaming que prefieras para que continúen escuchando todas tus
                canciones y, así, seguirás creciendo en TODAS las plataformas
                donde estés.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Artistas;
