import "./NavBar.css";
import Logo from '../../Images/Hearbound.png';
import { NavLink } from "react-router-dom";
import React from "react";

const Navbar = () => {
  let hamburgerNavBar = React.createRef();
  let overlay = React.createRef();
  let open = false;

  let handleClick = () => {
    const hamburger = hamburgerNavBar.current;
    const menu = overlay.current;

    if (!open) {
      hamburger.classList.add("open");
      menu.classList.add("menu");
    } else {
      hamburger.classList.remove("open");
      menu.classList.remove("menu");
    }
    open = !open;
  };

  return (
    <>
      <div id="overlay" ref={overlay}>
        <div className="overlayLinks">
          <NavLink to="/" activeClassName="active">
            <div className="overlaySelector"></div>
            <h1 className="overlayTitle">Home</h1>
          </NavLink>

          <NavLink to="/artistas" activeClassName="active">
            <div className="overlaySelector"></div>
            <h1 className="overlayTitle">Artistas</h1>
          </NavLink>
          <NavLink to="/usuarios" activeClassName="active">
            <div className="overlaySelector"></div>
            <h1 className="overlayTitle">Usuarios</h1>
          </NavLink>
          <NavLink to="/new" activeClassName="active">
            <button className="overlayButton">Pruebalo Gratis</button>
          </NavLink>
        </div>
      </div>
      <nav>
        <div className="navBar">
          <NavLink to="/" activeClassName="active">
            <div className="logoNavBar">
              <img src={Logo} alt='#'></img>
            </div>
          </NavLink>
          <div className="routeNavBar">
            <div className="textNavBar">
              <NavLink to="/" activeClassName="active">
                <div className="homeNavBar">Home</div>
              </NavLink>
              <NavLink to="/artistas" activeClassName="active">
                <div className="aboutNavBar">Artistas</div>
              </NavLink>
              <NavLink to="/usuarios" activeClassName="active">
                <div className="contactNavBar">Usuarios</div>
              </NavLink>
            </div>
            <NavLink to="/new" activeClassName="active">
              <div className="buttonNavBar">
                <button>Pruébalo Gratis</button>
              </div>
            </NavLink>
          </div>
          <div id="hamburger" ref={hamburgerNavBar} onClick={handleClick}>
            <div></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
